export const generalDom = {
	data() {
		return {
			// ==================== [Begin] Event listeners related =======================
			eventListenersAdd: function(eventId, callbackFunction) {
				window.addEventListener(eventId, callbackFunction);
			},
			eventListenersRemove: function(eventId, callbackFunction) {
				window.removeEventListener(eventId, callbackFunction);
			},
			isMouseEventOutsideElement: function(event, queryElement, ignoreElements) {
				// Checks if a mouse event (click / mousedown / mouseup) represented by the "event" parameter
				// is outseidr of a DOM element, represented by "queryElement".
				// The optional parameter "ignoreElements", if specified, is an array of DOM elements. These elements
				// will be considered "inside" the queryElement, i.e. clicking on them will return "true", same as if
				// the click would be on the queryElement.
				// NOTE: This is a pure javascript element, so to pass the value of queryElement from a Vue code
				// means using the "ref" target on the element and get the DOM reference by using this.$refs["elementRef"].
				var targetElements = [queryElement];
				if (ignoreElements !== undefined) {
					targetElements = targetElements.concat(ignoreElements);
				}
				var element = event.target;	// clicked element
				while(element) {
					//if (queryElement == element) {
					if (targetElements.find(target => target == element) !== undefined) {
						return false;
					}
					element = element.parentNode;
				}
				return true;
			},
			// ==================== [End] Event listeners related =======================
		}
	}
}
