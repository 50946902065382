<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper" align="center">
				<div class="modal-container" style="opacity: 0.9; display:table-cell; text-align:left; border-radius: 10px; ">





					<div id="divDoc" style="padding: 10px; border: 1px solid #AAAAAA; margin: 10px;">
						<div v-html="docText" style="overflow-y: auto; max-height: 400px; width: 600px;">
						</div>
						<div style="border: 1px solid #AAAAAA; margin-top: 10px; margin-bottom: 10px;"></div>
						<div style="text-align: center;">
							<input type="button" value="Închide" v-on:click.prevent="doClose()" style="font-size: 18px;" />
						</div>
					</div>





				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "CardInfoViewer",
	props: ["docText"],
	data: function() {
		return {};
	},
	methods: {
		doClose: function() {
			this.$emit("hideCardDoc");
		}
	}
}
</script>

<style>

</style>
