import { createStore } from 'vuex'

import MultiLanguage from "../storeML"

export default createStore({
	state: {
		userPassword: "",
		userName: "",
		serverPassword: "123",
		goptions: {
			currentCardPackIdId: "classicNew",
			scale: 0.8,
			actionOnReferentialCardClick: "image",	// "text" or "image"
			calculationModeId: "single",				// valid values: "single", "couple", "family", "diff". "potential"
			autoCloseDatesPanel: true,
			currentBackground: "02.jpg",
			backColor: "",
			language: "ro",
			serviceWorkerState: {
				newSwAvailable: false,
				deferredInstallPrompt: null
			}
		}
	},
	getters: {
	},
	mutations: {
		GLOBAL_StoreUserPassword: (state, password) => {
			state.userPassword = password;
		},
		GLOBAL_StoreUserName: (state, username) => {
			state.userName = username;
		},
		GLOBAL_StoreServerPassword: (state, password) => {
			state.serverPassword = password;
		}
	},
	actions: {
	},
	modules: {
		MultiLanguage
	}
})
