<template>
		<!-- Card and House details -->
		<div ref="cardHousDetailsPanel" class="overlay" :style="{width: visible ? '100%' : '0%'}" style="background-color: rgb(250, 255, 230);">

			<!-- Button to close the overlay navigation -->
			<a href="javascript:void(0)" class="closebtn" v-on:click.prevent="doClose()">&times;</a>

			<!-- Overlay content -->
			<div class="overlay-content" v-html="cardText"></div>
			<div class="overlay-content" v-html="houseText" style="margin-top: 75px;"></div>
			<!-- <div class="overlay-content">xxxxxx yyyyyy zzzzzz</div> -->
		</div>
</template>

<script>
export default {
	name: "CardHouseInfo",
	props: ["visible", "cardText", "houseText"],
	data: function() {
		return {};
	},
	methods: {
		doClose: function() {
			this.$emit("hideCardHouseInfo");
		}
	}
}
</script>

<style>

</style>