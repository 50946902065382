export const globalSettings = {
	data() {
		return {
			isProductionEnvironment: process.env.NODE_ENV != 'development',

			//apiUrl: this.isProductionEnvironment ? "/Master" : "http://localhost:9283/Master",	// Uncomment if the target API is the Net Framework web application (for Arvixe).
			apiUrl: process.env.NODE_ENV != 'development' ? "/Api" : "http://localhost:5004/Api",			// Uncomment if the target API is the Net Core web API.
			//apiUrl: process.env.NODE_ENV != 'development' ? "http://localhost:9283/Master" : "http://localhost:9283/Master",	// Uncomment if the target API is the Net Framework web application with CORS.
			//apiUrl: "https://us-central1-tarot-expert.cloudfunctions.net",
			//apiUrl: process.env.NODE_ENV != 'development' ? "https://us-central1-tarot-expert.cloudfunctions.net" : "http://localhost:5002/Api",	// Uncomment if the target API is Firebase.

			appVersion: "2.0",

			enumPresenter: {
				getName: function(presenter, val) {
					var element = presenter.find(el => el.value == val);
					return (element != null ? element.name : "???");
				},
				aspects: [
					{ value: 1, name: "Banda", ml: "banda" },
					{ value: 2, name: "Dialectica", ml: "dialectica" },
					{ value: 3, name: "Oglinzile", ml: "oglinzile" },
				]
			},
			enums: {
				aspects: { Banda: 1, Dialectica: 2, Oglinzile: 3 }
			},
			calculationModes: [
				{ id: "single", name: "Persoană", ml: "persoana" },
				{ id: "couple", name: "Cuplu si alianță", ml: "cuplu_si_alianta" },
				{ id: "family", name: "Roi", ml: "roi" },
				{ id: "diff", name: "Diferențial", ml: "diferential" },
				{ id: "potential", name: "Potențial", ml: "potential" },
				{ id: "meditation", name: "Meditation", ml: "meditation" }
			],
			tableBackgrounds: [
				{ id: "none", name: "(fara)" },
				{ id: "bkgVelvet4.jpg", name: "Catifea verde" },
				{ id: "08.jpg", name: "Catifea albastră" },
				{ id: "05.jpg", name: "Lemn roșcat" },
				{ id: "09.jpg", name: "Lemn închis" },
				{ id: "01.jpg", name: "Catifea neagră" },
				{ id: "02.jpg", name: "Stele" },
				{ id: "04.jpg", name: "Scânduri" },
				{ id: "06.jpg", name: "Sugi Ban (v))" },
				{ id: "07.jpg", name: "Sugi Ban (h))" },
				{ id: "solidColor", name: "Culoare" }
			],
			getLabelFromId: function(collection, id, defaultValue) {
				var foundVal = collection.find(element => element.id == id);
				return foundVal !== undefined ? foundVal.name : defaultValue;  
			},
			getMlidFromId: function(collection, id, defaultValue) {
				var foundVal = collection.find(element => element.id == id);
				return foundVal !== undefined ? foundVal.ml : defaultValue;  
			},
			splitToDigits: function(numberStr) {
				var arr = Array.from(numberStr);
				var retArr = [];
				for (var i = 0; i < arr.length; i++) {
					retArr[i] = 1 * arr[i];
				}
				return retArr;
			},
			reduce: function(number, base, stopOnArr) {
				// If stopOnArr is specofied, this means that the calculation must stop if the number is in the stopOnArr array.
				if (stopOnArr != null) {
					for (var i = 0; i < stopOnArr.length; i++) {
						if (stopOnArr[i] == number) {
							return number;
						}
					}
				}
	
				if (number == 0) {
					return base;
				}
				
				if (number < 0) {
					return 0;
				}
				
				if (number <= base) {
					return number;
				}
	
				var numberStr = "" + number;
				var arrDigits = this.splitToDigits(numberStr);
				var sum = 0;
				for (var i = 0; i < arrDigits.length; i++) {
					sum += arrDigits[i];
				}
				if (sum <= base) {
					return sum;
				}
				number = this.reduce(sum, base, stopOnArr);
				return number;
			},
			getCardSet: function(cardPacks, cardPackId) {
				var cardSet = cardPacks.find(el => el.id == cardPackId);
				return (cardSet != null ? cardSet : cardPacks[0]);
			},
			func1: function() {
				return this.func2(1);
			},
			func2: function(arg) {
				return arg + 1;
			},
			stringToDate: function(dateStr) {
				/*
				* Converts a string in d-m-y format, to a date, and returns it.
				* If the conversion fails, null is returned
				*/
				// Split and check the number of atoms.
				var arr = dateStr.split("-");
				if (arr.length != 3) {
					return null;
				}
				for (var i = 0; i < 3; i++) {
					if (isNaN(arr[i])) {
						return null;
					}
				}

				var day = 1 * arr[0];
				var month = 1 * arr[1];
				var year = 1 * arr[2];

				// Rough test - the Date object is generally too permissive, so this won't filter much.
				var isDate = true;
				var date = null;
				try {
					date = new Date(year, month - 1, day);
				}
				catch (ex) {
					isDate = false;
				}
				if (!isDate) {
					return null;
				}

				// Compare the date fields with the atom numeric values.
				var d = date.getDate();
				var m = date.getMonth();
				var y = date.getFullYear();
				if (date.getDate() != day || date.getMonth() != month - 1 || date.getFullYear() != year) {
					return null;
				}

				return date;
			},
			getLocalCredentials: function() {
				var userName = localStorage.hasOwnProperty("userName") ? localStorage.userName : null;
				var password = localStorage.hasOwnProperty("password") ? localStorage.password : null;
				return {userName: userName, password: password};
			},
			dateToIsoStr: function(date, dateFormat, partsToInclude, dateSeparator, timeSeparator, dtSeparator) {
				/*
				Converts a javascript Date object to an ISO formatted string.
					dateFormat: DMY, MDY, YMD etc.
					partsToInclude: DT, D, or T. Indicates what is to be returned - the date part, the time part, or both.
					dtSeparator: if DT format is specified, this is the delimiter between the date part and the time part.
				*/
				// Defaults
				dateFormat = (dateFormat != null ? dateFormat : "DMY").toUpperCase();
				partsToInclude = (partsToInclude != null ? partsToInclude : "DT");
				dateSeparator = (dateSeparator != null ? dateSeparator : "-");
				timeSeparator = (timeSeparator != null ? timeSeparator : ":");
				dtSeparator = (dtSeparator != null ? dtSeparator : " ");

				var includeDate = partsToInclude.includes("D");
				var includeTime = partsToInclude.includes("T");

				var dayStr = String(date.getDate()).padStart(2, '0');
				var monthStr = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
				var yearStr = date.getFullYear();
				var hourStr = String(date.getHours()).padStart(2, '0');
				var minuteStr = String(date.getMinutes()).padStart(2, '0');
				var secondStr = String(date.getSeconds()).padStart(2, '0');

				// Arrange the date part according to the required format.
				var arrDateParts = ["", "", ""];
				var yearPos = dateFormat.indexOf("Y");
				var monthPos = dateFormat.indexOf("M");
				var dayPos = dateFormat.indexOf("D");
				arrDateParts[yearPos] = yearStr;
				arrDateParts[monthPos] = monthStr;
				arrDateParts[dayPos] = dayStr;
				
				var datePartStr = arrDateParts.join(dateSeparator);
				var timePartStr = [hourStr, minuteStr, secondStr].join(timeSeparator);

				return (includeDate ? datePartStr : "") + (includeDate && includeTime ? dtSeparator : "") + (includeTime ? timePartStr : "");
			},
		}
	}
}
