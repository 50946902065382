export const cardsDocumentation = {
	data() {
		return {
			cardsDocument:
				[
					{
						id: "none",
						doc: "[doc] none"
					},
					{
						id: "Bateleur",
						doc: "<b>Le Bateleur</b><hr/>Prima lamă din Tarotul de Marsilia, Magicianul, îl reprezintă pe novicele aflat la început de drum.<br/>În fața lui, toate elementele sunt prezente, însă acestea se află într-o totală dezordine, ca și instrumentele aruncate vraiște într-o cutie, un puzzle nearanjat. Aceste elemente reprezintă întregul său patrimoniu, capacitățile sale. Se va folosi oare de toate aceste calități pentru a avansa pe drumul la care care a pornit spre cucerirea Lumii?<br/><br/>Cuvinte cheie: Mă joc. Mă amuz. Descopăr. Debut. Început. Copilărie. Potențial Creativ. Energie vitală. Îndemânare. Iscusință. Iluzii. Eclectism"
					},
					{
						id: "Papesse",
						doc: "<b>La Papesse</b><hr/>Magicianul a plecat spre cucerirea Lumii. Pe drumul său plin de peripeții întâlnește diferiți maeștri, fiecare dintre aceștia înlesnindu-i sau îngreunându-i drumul, adesea destul de dificil. Primul personaj ce-i iese în cale este chiar Papesa, aceasta simbolizând prima femeie, cea care ne aduce pe lume, mama... <br/>Papesa implică întregul univers matern. Ea simbolizează puterea de a comunica cu invizibilul intrauterin, puterea absolută de a aduce copii pe lume, femeia, cea care ne deschide prima poartă, cea care deșiră primul voal. Ea reprezintă trecerea de partea cealaltă a voalului, prima poartă, deschiderea drumului, punctul de frontieră către Tărâmul Făgăduinței . Aceasta îi propune Magicianului să treacă din lumea profanului în cea a sacrului, din lumea iluziilor într-aceea a înțelepciunii. În final, Magicianul vine pe lume. Nu există o naștere mai importantă ca nașterea de sine. <br/>Cuvinte cheie: Dezvălui(Revelez).Dau naștere.Ascund.Disimulez.Naștere.Facere(Făurire).Cunoaștere.Cultură.Pasaj.Prag.Deschidere.Mamă.Inițiere.Secret.Femeie înțeleaptă.Moașă.Feminin.Invizibil.Interioritate.Mister.Maieutică.Revelație.Devoalare.Trecere de la un plan la altul."
					},
					{
						id: "Impératrice",
						doc: "<b>l'Impératrice</b><hr/>A treia lamă din Tarotul de Marsilia, Împărăteasa, definește partea masculină din fiecare feminin. Întâlnind această arcana, Magicianul se lasă purtat pe tărâmul autorității, manifestării, forței și expresiei. Arcana androgină, Împărăteasa, la o observație mai atentă, scoate la iveală mărul lui Adam, lucru nu prea comun pentru o reprezentantă a sexului feminin.<br/><br/>Cuvinte cheie: Spun. Afirm. Decret. Inteligență și gândire creativă. Expresie. Vorbire. Integrarea forțelor psihice în acțiune. Manifestare. Încarnare. Mediumnitate. Autoritate, Perspicacitate, Luciditate, Echilibrul gândirii, Puterea femininului."
					},
					{
						id: "Empereur",
						doc: "<b>L'Empereur</b><hr/>Împăratul întruchipează autoritatea, raționalul și teritoriul și este punctul de legătură între lumea spiritelor și cea a materiei. Împăratul evocă de asemenea și tema tatălui, iar colierul ce-l poartă la gât în formă de spic de grâu anunța deja coloana vertebrală ce o vom regăsi în Arcana fără Nume : Arcana XIII.<br/>Însă, cum se face oare ca un Împărat să fie desculț?<br/><br/>Cuvinte cheie: Structurez. Interzic. Autorizez. Rigiditate, Teritoriu, Materie, Structură, Lucru, Realizare, Concret, Limita formei, Gândire rațională, Autoritate, Rigoare, Raționalitate, Paternitate."
					},
					{
						id: "Pape",
						doc: "<b>Le Pape</b><hr/>Arcana V, Papa, ne dezvăluie o altă latură a tatălui. În timp ce Împăratul este tatăl autoritar și riguros, Papa îl întruchipează pe tatăl blajin, tată ce dispune de o funcție securizantă și hrănitoare pentru copil. Papa ne indică singura cale de urmat în viață : calea Inimii. <br/><br/>Cuvinte cheie: Binecuvântez. Protejez. Reasigur. Alianță. Uniune. Binecuvântare. Umanism. Transmitere. Învățătura. Dogmatism. Recunoaștere. Legătură cu lumea spirituală. Tată."
					},
					{
						id: "Amoureux",
						doc: "<b>L'Amoureux</b><hr/>Arcana VI, Îndrăgostitul pare a fi pus în fața unei situații în care este nevoit să facă o aleagere. Să ne aflăm oare aici în fața unei reprezentări a mitului lui Oedip? Îndrăgostitul este însă adesea înțeles greșit. El nu reprezintă neapărat arcana alegerii ci mai degrabă pe cea a angajamentului. Când ne luăm un angajament nu renunțăm la nimic. Angajamentul nu presupune neapărat renunțare. Ideea de angajament calmează angoasa de a alege.<br/> <br/>Cuvinte cheie: Angajament, Afectiv, Dragoste, Libertate, Alegere, Dorință, Protecție, Energie sexuală, Vitalitate. Adaptabilitate și frica de a renunța. A se pune în serviciul cuiva. Dragostea față de un demers spiritual."
					},
					{
						id: "Chariot",
						doc: "<b>Le Chariot</b><hr/>Această arcană ne duce adesea cu gândul la ideea de triumf, reușită. Ea relevă imaginea unui prinț stăpân atât pe sine cât și pe univers. Observând însă imaginea mai îndeaproape, începem să ne îndoim de această perfecțiune absolută. Caii nu privesc chiar direcția spre care galopează și, mai mult, trag în direcții diferite. Conducătorul, prințul triumfător, nu are nici măcar frâiele cu care să-și controleze caleașca iar roata dispusă în spate este plasată în așa fel încât este destul de greu de imaginat că vehiculul va înainta. Arcana VII nu avansează!<br/><br/>Cuvinte cheie: Triumf. Stăpânire. Cale. Dualitate. Putere. Reușită socială. Coaching. Formator. Realizare socială. Necesitatea de a-și stăpâni pulsiunile instinctive și tensiunile contradictorii."
					},
					{
						id: "Justice",
						doc: "<b>La Justice</b><hr/>Numărul 8 este cel al transformărilor. <br/>Această arcană aș prefera s-o numim justețe. Ea simbolizează întâlnirea cu sine însuși, echilibrul între lumea interioară și cea exterioară. <br/>Această justețe nu este însă cea a oamenilor. Dacă ea ne pare inflexibilă, severă, autoritară, austeră este pentru că avem în față justiția imparțială, pură și incoruptibilă. Este balansierul subtil aflat în adâncul sufletelor noastre care, atunci când o lege din natură este deranjată, acționează astfel încât să trezească și să afineze conștiința noastră.<br/><br/>Cuvinte cheie: Restabilesc. Echilibrez. Conștiință. Transformare. Armonizare. Echilibru. Justiție socială, Energie. Libertate. Oglinda sinelui. Ajustare. Justețe. Adaptare. Căutarea cauzelor profunde. Față în față cu sine însuși."
					},
					{
						id: "Ermite",
						doc: "<b>L'Ermite</b><hr/>Ermitul, figură a bătrânului înțelept, este adesea redus la ideea de solitudine sau izolare. Confruntați cu această imagine realizăm că ne aflăm în fața ideii de transmutare a solitudinii, de ridicare a acesteia la un nivel superior. Ermitul este un meditator, un tată al deșertului, un pelerin. El îi iluminează pe ceilalți pentru că a reușit să întrețină vie flacăra descoperită în interiorul său.<br/>Dacă autorii vechiului Tarot de Marsilia au scris ''Hermit'' cu ''H'' este pentru că au dorit să evoce prezența lui Hermes, zeul care leagă cerul și pământul, zeul alchimiei și hermetismului.<br/><br/>Cuvinte cheie: Ghidez. Luminez. Pelerin. Căutare. Strămoș.Înțelepciune. Profunzime. Singurătate. Interiorizare. Apropiere de natură. Șamanism. Sensibilitate. Călăuzire. Inițiere. Întoarcere la sursă."
					},
					{
						id: "Roue",
						doc: "<b>La Roue</b><hr/>Mecanism complex, Arcana X simbolizează întoarcerea istoriei familiale sau personale. Roata Norocului desemnează istoriile care se repetă, amintirile arhaice, schemele repetitive. Atât timp cât nu am eliberat în inconștientul nostru programările genetice, nu vom putea avansa.<br/>Analizată din perspectiva Roţii Norocului, libertatea noastră depinde de capacitatea pe care o avem de a ne debarasa de toată greutatea acumulată prin valorile ce ne-au fost transmise din generaţie în generaţie, greutate ce ne inhibă şi ne împiedica să acţionăm spontan.<br/>Avem în faţa, aşadar, una dintre marile arcane de libertate însă, în acelaşi timp, şi o arcană de tranziţie.<br/><br/><br/>Cuvinte cheie : Repet. Reproduc. Inventez. Avangardă. Inovație. Revoluție. Repetiție. Obișnuință. Tranziție. Determinism. Liber arbitru. Karma ( legea cauză efect). Temporalitate. Evoluție spirituală. Cunoaștere de sine."
					},
					{
						id: "Force",
						doc: "<b>La Force</b><hr/>Arcana XI, Forța, ni se destăinuie într-un cadru fără peisaj, lipsit de orice arhitectură în depărtare sau de solul prezent în toate celelalte arcane. Forța reprezintă autonomie, centraj, interioritate. Singurul său reper este ea însăși.<br/>Această arcană este și aceea a prieteniei și a filiației.<br/><br/>Cuvinte cheie : Mă lupt. Câștig. Mă centrez. Mă autodepășesc. Rezist. Fermitate. Centraj. Aliniere. Energie. Stăpânire de sine. Încredere. Autonomie. Independență. Legătură cu sursa. Vindecare spirituală."
					},
					{
						id: "Pendu",
						doc: "<b>Le Pendu</b><hr/>Arcana XII, Spânzuratul, reprezintă arcana eliberării, a detașării și a încrederii. Această ne amintește că pentru a ne elibera de ceea ce ne ține atașați, blocați, imobili trebuie să fim capabili să ne schimbăm punctul de vedere, să putem privim lucrurile dintr-un alt unghi. Cu ambele mâini legate la spate, personajul exprimă o oarecare vulnerabilitate. Într-adevăr, atunci când ne plasăm mâinile în poziția aceasta, renunțăm a ne mai proteja, ne arătăm încrezători, disponibili și nu opunem nicio rezistentă.<br/>Una dintre ideile de bază transmise de Arcana XII este aceea că dacă în loc să rezistăm presiunilor curenților ne-am lăsa purtați de ei, am ajunge mai repede și fără prea mult efort la malul mult râvnit.<br/><br/>Cuvinte cheie : Mă abandonez. Abandonez. (Eu) Deleg. Mă conformez. Aștept. Dedublare. Delegație. Inversarea valorilor. Eliberare. Detașare. Încredere. Sacrificiul ego-ului. Non-violență. Supunere la legile gravitației spirituale"
					},
					{
						id: "Mort",
						doc: "<b>Pas nommée</b><hr/>Arcana XIII. Nu, nu este moartea, ci Arcana Fără Nume. Ne lovim aici, încă o dată, de experiența detașamentului, însă de data aceasta avem de-a face cu o despuiere radicală, nimic nu mai rezistă aici, nici măcar capetele încoronate.<br/>Regăsim în această carte coloana vertebrală în formă de spic de grâu deja întâlnită sub forma medalionului purtat de Împărat. Această arcană este, bineînțeles, aceea a vieții, a renașterii și a reconstrucției. Scheletul ne duce cu gândul la structură, la construcție. Esențială în această arcană este căutarea identității. Prima întrebare pe care ne-o putem adresa aici este: ''Cine sunt eu ?'' <br/>Construirea identității presupune mai întâi distrugerea personalității. Nimic nu-i rezistă neobositei dansatoare, femeia cu coasa. Totul este în permanentă schimbare.<br/><br/>Cuvinte cheie: Eu sunt. Mă transform. Renasc. Distrug. Elimin. Autosabotaj. Identitate. Moarte. Renaștere.Transformare. Structurare. Construcție. Curățenie. Despuiere. Creștere spirituală. Căutarea propriei identități. Revalorizare de sine."
					},
					{
						id: "Tempérance",
						doc: "<b>La Tempérance</b><hr/>Odată ce experiența detașării și a despuierii a fost trăită și integrată, abordăm arcana XIV, Temperanța. Armonizarea polarităților. Personajul angelic prezent în această lamă ține în mâini două urne, vărsând dintr-una în cealaltă un lichid. Acest lichid simbolizează fluxul energetic. Ea amintește astfel de Vărsător, mesager al comunicării subtile. Temperanța este arcana comunicării pe trei planuri: comunicarea socială și personală, comunicarea cu sine însuși și comunicarea cu lumile invizibile. Temperanța ne invită să ne ridicăm mai presus de noi înșine. <br/><br/>Cuvinte cheie: Comunic. Schimb. Stăpânesc. Captez. Dependență. Comunicare. Telepatie. Stăpânire de sine. Respirație. Cunoaștere de sine. Puteri șamanice. Fraternitate."
					},
					{
						id: "Diable",
						doc: "<b>Le Diable</b><hr/>Diavolul, arhetip al dualității reprezintă întregul plan al mentalului. În același timp, coarnele de cerb evocă în limbajul alchimic noțiunea de serviciu. <br/>Nimic nu este negativ în Tarot, nici măcar Diavolul. Această arcana aduce multă energie, dispune de o imensă forță. Ideea cu care vom fi însă confruntați este : în serviciul cărei valori depun toată această energie de care dispun? Diavolul din Tarot mai reprezintă și marea arcana a fricii. Frica de diavol este de fapt proiecția culpabilității și a fantasmelor noastre interioare. <br/><br/>Cuvine cheie: Manipulez. Sabotez. Farmec. Servesc. Energie. Putere. Tărie. Dualitate. Șarm. Magnetism. Resentiment. Angoasă. Amenințare. Fascinație. Alienare. Mental. Frică. Seducție. Serviciu. Sexualitate. Iluzii"
					},
					{
						id: "Maison",
						doc: "<b>La Maison</b><hr/>Arcana XVI este tradusă atât ''Casa Domnului'' cât și ''Turnul''. Ce se întâmplă de fapt aici? O energie se cristalizează la nivelul ego-ului. Ego-ul suferă. Rană narcisică. Iată marea arcană a somatizării. Zona cervicală se inflamează, gâtul este rigid,iar omoplații încordați. <br/>Arcana XVI, amintind de Turnul Babel, face referință și la problematica limbajului: limbaj închis, citadelă blindată, suferințe autiste, tăcere asurzitoare. <br/>Casa Domnului întruchipează dislocarea Ego-ului, episod ce precede plenitudinea Sine-ului. <br/><br/>Cuvinte cheie: Explodez . Sufoc. Agresez. Deconstrucție. Implozie. Orgoliu. Protecție exagerată. Violență. Limbaj. Închidere. Forță de caracter. Inspirație divină"
					},
					{
						id: "Étoile",
						doc: "<b>L'Étoile</b><hr/>Mai întâi de toate Steaua reprezintă protecția absolută. <br/>Strălucirea Arcanei XVII este dată de smerenia autentică, spiritualitatea adâncă și dragostea infinită. Lichidul de culoarea albastră pe care personajul din imagine îl varsă din abundență este tocmai generozitatea sa. Asistăm la o restituire a energiei pământului. Această deversare simbolizează abandonul tuturor puterilor deținute. Steaua ne amintește de eternul feminin. Toate planurile - uman, animal, vegetal, mineral, cosmic - sunt puse în relație în această arcana. Adevărul este gol iar Steaua ne propune să renunțăm la orice fel de putere. Nu este deloc ușor. Ne este imposibil să renunțăm la ceva ce nu cunoaștem, ce nu am experimentat vreodată. <br/><br/>Cuvinte cheie: Restitui. Dau. Speranță. Iertare. Partaj. Nuditate. Încredere. Dragoste necondiționată, Compasiune. Eternul feminin. Adevăr. Sens cosmic și spiritual. Dezvoltare. Abandon. Încredere absolută. Dezgolire."
					},
					{
						id: "Lune",
						doc: "<b>La Lune</b><hr/>Odată cu Arcana XVIII penetrăm universul emoțiilor creative. Aceasta ne invită să depășim inteligența rațională și să explorăm teritorii necunoscute ca de exemplu cel al intuiției, al inspirației și al percepțiilor subtile. Ea reprezintă în același timp atât o poartă de ieșire către lumile pline de mister cât și o poartă de intrare către zonele cele mai ascunse ale sufletului. <br/>Întregul proces creativ presupune sublimarea sau canalizarea fricilor și emoțiilor. Fără această exprimare a creativității, emoționalul invadează ființa umană în toate dimenisunile acesteia. <br/>Acest proces presupune și creația a tot ceea ce este viu. Îi rămâne însă artistului sarcina de a aduce la viață turbulențele emoționale cuibărite în sufletul său.. <br/><br/>Cuvinte cheie: Reflectez. Visez. Mă ascund. Aparență. Tristețe. Reflecție, Oglindă. Amintire. Clarviziune. Sensibilitate. Suflet. Subconștient. Afectivitate instinctivă. Anima"
					},
					{
						id: "Soleil",
						doc: "<b>Le Soleil</b><hr/>Soarele reprezintă perechea Lunii, animus acolo unde avem anima, Yang acolo unde vibrează Yin.     <br/>Această arcană ne invită să măsurăm importanța fraternității umane în tot ceea ce înseamnă proiect umanitar sau reconstrucție socială. Arcana XIX reprezintă tot ceea ce este nou, uman, fraternal, jovial. <br/>La o primă observație, comparând cele două arcane, remarcăm faptul că Luna are reprezentați doi lupi în timp ce Soarele are reprezentați doi copii. Să fi încercat oare autorii anonimi ai Tarotului să evoce aici mitul lui Remus și Romulus ? <br/>Un lucru este sigur, în Arcana XIX se plănuiește construirea unei noi cetăți ! <br/><br/>Cuvinte cheie: Construiesc. Partajez. Ard. Strălucesc. Încălzesc. Bucurie. Copilărie. Partaj social. Difuziunea energiei. Forță creativă. Construcție. Căldură. Fraternitate. Construcție. Umanism triumfător."
					},
					{
						id: "Jugement",
						doc: "<b>Le Jugement</b><hr/>Zgomotoasa Judecată vine să trezească conștiințele, să ne ridice din mormânt, cu condiția însă să ne fi eliberat înainte de propria ignoranță și culpabilitate. Iată așadar arcana trezirii, a creșterii spirituale, a resurecției. <br/>Recunoaștem aici un semn al deschiderii spiritului, corpului și sufletului la perceperea unui mesaj de origine celestă. Poate că ar trebui să meditam asupra încrederii ce o acordăm propriei noastre intuiții și asupra posibilității de a dezvolta în noi ascultarea interioară. <br/>Această lamă mai poate reprezenta și atingerea unui anumit nivel de evoluție la care subiectul a devenit apt de judecată sau discernământ . <br/><br/>Cuvinte cheie: Mă eliberez. Judec. Transmit. Culpabilitate. Intuiție. Inspirație. Trezire. Sentiment de culpabilitate. Angajamentul de a trezi conștiințele. Muzică. Vorbire. Renaștere. Judecata de sine și a celor din jur."
					},
					{
						id: "Monde",
						doc: "<b>Le Monde</b><hr/>Lumea, arcană holistică, evocă sfârșitul unui drum. Aceasta reprezintă o hologramă a conștiintei universale : aici, totul este prezent. Dacă luăm doar o parte a arcanei vom găsi în ea totalitatea Tarotului. Lumea este cea pe care eu o cuceresc. Pentru fiecare dintre noi această experiență este una diferită. Această arcana simbolizează și viața socială, familială și spirituală . Ea mai poate evoca și dansul sau împlinirea personală. Arcana XXI poate, de asemenea, inspira un sentiment de închidere. Există lumi în care ne simțim prizonieri, porți prea greu de deschis, inexorabil închise, colivii de aur. Sub diferite aspecte, arcana XXI reflectă dificultatea pe care o avem uneori în a ne ocupa locul în această lume. <br/><br/>Cuvinte cheie: Mă unesc. Sufoc. Particip. Conștiință. Totalitate. Globalitate. Expresie. Spirit de sinteză. Realizare. Reunificare. Ecologie. Dans. Arhaic feminin. Nevoia de expresie socială. Sentiment de închidere. Căutarea propriului loc în societate. Adaptabilitate. Sinteză."
					},
					{
						id: "Fol",
						doc: "<b>Le Fol</b><hr/>Atunci când un drum se sfârșește, un altul începe. Astfel Nebunul își ia bastonul de pelegrin și pleacă mai departe urmând mai degrabă drumul înțelepciunii decât pe cel al nebuniei. Nimeni nu-l poate împiedica, el călătorește fără a respecta un drum anume însă nu se pierde. <br/>Nebunul este una dintre arcanele tulburătoare ale Tarotului. Această poziționare incertă în structura jocului, datorată în particular absenței numărului, a făcut din această arcana un simbol al iraționalității. Ce este însă iraționalul dacă nu o altă  formă de abordare a realului ? <br/>Nebunul reprezintă privirea noastră asupra tot ceea ce ne scapă, asupra tot ceea ce ne pare de neînțeles. Acesta înfățișează libertatea fundamentală a omului în fața determinismului legilor naturii, acea libertate de a acționa într-o lume în care nu mai recunoaștem valorile și ale cărei limite dorim să le depășim. <br/>Nebunul este într-adevăr liber deoarece acesta se deplasează într-o lume fără trecut, fără viitor deci fără dualitate . <br/>Nebunul refuză să privească lumea ca pe ceva exterior lui. El se aruncă fără teamă, se amestecă cu lumea, el este lumea. <br/><br/>Cuvinte cheie: Călătoresc. Mă rătăcesc. Rebeliune. Contrabandă. Fără domiciliu fix. Diferență. Rătăcire. Nebunie. Înțelepciune. Irațional. Călăuzire. Libertate. Pelerin. Marginalitate. Originalitate. Toleranță. Determinare. Geniu. Dorința de aventură. Constructor universal. Provocator în fața societății"
					}
				]
			}
	}
}