<template>
	<div class="full-height-width" style="border: 0px solid red;">
		<div class="grid-button-row">
			<div class="grid-button-cell">
				<input type="text" v-model="inputUserName" placeholder="(nume)" autocapitalize="none" autocomplete="off" style="width: 50%; font-size: 25px;">
			</div>
		</div>
		<div class="grid-row">
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(1)" @dblclick.prevent="onDoubleClick()">&nbsp;</div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(2)" @dblclick.prevent="onDoubleClick()"></div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(3)" @dblclick.prevent="onDoubleClick()"></div >
		</div>
		<div class="grid-row">
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(4)" @dblclick.prevent="onDoubleClick()">&nbsp;</div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(5)" @dblclick.prevent="onDoubleClick()"></div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(6)" @dblclick.prevent="onDoubleClick()"></div >
		</div>
		<div class="grid-row">
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(7)" @dblclick.prevent="onDoubleClick()">&nbsp;</div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(8)" @dblclick.prevent="onDoubleClick()"></div>
			<div class="grid-cell" :style="{'background-color': randomColor() }" @click.prevent="onClick(9)" @dblclick.prevent="onDoubleClick()"></div >
		</div>
		<div class="grid-button-row">
			<div class="grid-button-cell2" @click.prevent="checkUserNamePassword(true)" style="max-width: 200px; cursor: pointer; border: 1px black solid;">OK</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import ky from "ky";
import { globalSettings } from "@/mixins/globalSettings";

export default {
	name: "LoginUserNamePassword",
	mixins: [globalSettings],
	data: function() {
		return {
			inputPassword: "",
			inputUserName: ""
		}
	},
	created: function() {
		if (localStorage.userName != "") {
			this.inputUserName = localStorage.userName;
		}
		if (!this.$route.params.hasOwnProperty("noCredentialsCheck")) {
			this.checkUserNamePassword(false);
		}
	},
	mounted: function() {
	},
	watch: {
		"inputUserName": function() {
			this.inputPassword = "";
		}
	},
	computed: {
		...mapState([
			"serverPassword", "userPassword", "userName"
		])
		//,
		// Other computed props here
	},
	methods: {
		...mapMutations([
				"GLOBAL_StoreUserPassword", "GLOBAL_StoreServerPassword", "GLOBAL_StoreUserName"
			]),
		randomColor: function() {
			return "rgb(" + Math.floor(Math.random() * 256) + "," + Math.floor(Math.random() * 256) + "," + Math.floor(Math.random() * 256) + ")";
		},
		onClick: function(cellId) {
			this.inputPassword += cellId;
			if (this.inputPassword.length > 50) {
				this.inputPassword = "";
			}
		},
		onDoubleClick: function() {
			this.inputPassword = "";
		},
		checkUserNamePassword: function(useManualCredentialsInput) {
			/*
			useManualCredentialsInput - True: indicates that the function considers the credentials entered
			by the user in tis view. False - indicates that the function must consider and use the cached credentials.
			*/
			if (useManualCredentialsInput) {
				localStorage.userName = this.inputUserName;
				this.GLOBAL_StoreUserName(this.inputUserName);
				this.GLOBAL_StoreUserPassword(this.inputPassword);
			}
			else {
				console.log(">>> localStorage.password", this.inputPassword);
			}
			ky(this.apiUrl + "/CheckUserPassword", {
				method: "GET",
				searchParams: {
					user: this.userName,
					password: this.userPassword
				},
				cache: "no-cache",
				timeout: 5000,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				//this.GLOBAL_StoreServerPassword(responseJson.p);
				if (responseJson.valid) {
					// Save the password in the local strage.
					if (useManualCredentialsInput) {
						localStorage.password = this.inputPassword;
					}
					// Go to the main screeen
					this.$router.push({ name: "cardsTable" });
				}
				else {
					if (useManualCredentialsInput) {
						alert("Incorect");
					}
				}
			})
			.catch((err) => {
				alert("Error: " + err.message);
			});
		}
	}
}
</script>

<style>
		.full-height-width {
			position: absolute;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: stretch;
			left: 0px;
			right: 0px;
			top: 0px;
			bottom: 0px;
		}

		.grid-row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: stretch;
			flex: 1;
			background-color: white;
		}

		.grid-cell {
			flex: 1;
			margin: 2px;
			text-align: center;
			line-height: 75px;
			font-size: 30px;
			background-color: #c02222;
		}

		.grid-button-row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			flex: 0.5;
			background-color: lightgrey;
			margin: 0px;
		}

		.grid-button-cell {
			flex: 3;
			text-align: center;
			align-items: center;
			/* line-height: 100px; */
			font-size: 30px;
			background-color: lightgrey;
		}

		.grid-button-cell2 {
			flex: 3;
			text-align: center;
			align-items: center;
			/* line-height: 100px; */
			font-size: 30px;
			background-color: #999999;
			border-radius: 10px;
		}

		div .grid-button-cell2:hover {
			background-color: white;
		}
</style>
