<template>
	<div>
		<div style="margin-bottom: 40px;">
			<div style="display: inline-block; font-family: Times, 'Times New Roman', serif; font-size: 20px; color: white;">{{ ML.trans("meditation") }}</div>
			<span style="float: right;">
				<img src="@/assets/images/button-minor.png" :style="{'border-color': getButtonBorderColor(2)}" style="border: 2px solid; border-radius: 100px; vertical-align: middle;" alt="Major" title="Major" @click="toggleCardsAreaVisibility(2)">
			</span>
			<span style="float: right;">
				<img src="@/assets/images/button-major.png" :style="{'border-color': getButtonBorderColor(1)}" style="border: 2px solid; border-radius: 100px; vertical-align: middle;" alt="Major" title="Major" @click="toggleCardsAreaVisibility(1)">
			</span>
			<span style="float: right; text-align: center;">
				<input type="range" :min="1" :max="5" v-model.number="cardsPerRow">
				<span v-if="(cardsArea & 2) != 0">
					<br/>
					<select v-model="cardsColor" style="margin-top: 5px;">
						<option v-for="color in [1,2,3,4]" :key="color" v-bind:value="color">{{ getColorName(color) }}</option>
					</select>
				</span>
			</span>
		</div>

		<div class="meditation-cards-area" ref="cardsDiv">
			<div v-for="cardIndex in cardIndexes" :style="cardLayout" style="display: inline-block; margin-bottom: 20px; vertical-align: top;">
				<img style="width: 100%;" v-bind:src="getImage(cardIndex, true)" @click="openCardPopup(cardIndex)">
			</div>
		</div>

		<ModalPopupFade :visible="popup.visible" :backcolorOverlay="'#002222ff'" @askClose="closeCardPopup()" popupLayoutClass="popupEditLayout">
			<div style="background-color: grey; color: red; height: 95%">
				<img style="height: 100%;" v-bind:src="getImage(popup.zoomCardIndex, true)" @click="closeCardPopup()">
			</div>
		</ModalPopupFade>

	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { housesInfo } from "@/mixins/houses";
import { cardImagesInfo } from "@/mixins/cardImagesInfo";
import { cardsDocumentation } from "@/mixins/cardsDocumentation";
import { globalSettings } from "@/mixins/globalSettings";
import ky from "ky";
import { generalDom } from "@/mixins/generalDom";
import ModalPopupFade from  "@/components/ModalPopupFade.vue"

export default {
	name: "DatesPanel",
	mixins: [housesInfo, cardImagesInfo, cardsDocumentation, globalSettings, generalDom],
	components: {ModalPopupFade},
	props: {
	},
	data: function() {
		return {
			cardIndexes: [],
			cardsArea: 3,		// Bitmap values (use binary operators) 1 = major, 2 = minor => 3 (1 & 2) = all
			cardsColor: 4,		// (relevant if cardArea != 1) Values: 1 = bita, 2 = cupa, 3 = spada, 4 = moneda
			cardsPerRow: 3,
			cardLayout: {
				width: "45%",
				"margin-left": "2.5%",
				"margin-right": "2.5%"
			},
			popup: {
				visible: false,
				zoomCardIndex: 0
			}
		};
	},
	watch: {
		cardsPerRow: function() {
			this.computeCardLayout();
		},
		cardsColor: function() {
			this.applyFilter();
		}
	},
	created: function() {
		this.computeCardLayout();
		this.applyFilter();
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		})
	},
	methods: {
		closeCardPopup: function() {
			this.popup.visible = false;
		},
		openCardPopup: function(cardIndex) {
			this.popup.zoomCardIndex = cardIndex;
			this.popup.visible = true;
		},
		scrollCardsDivTop: function() {
			this.$refs.cardsDiv.scroll({
				top: 0,
				behavior: "smooth"  // 👈
			});
		},
		getColorName: function(color) {
			switch(color) {
				case 1:
					return this.ML.trans("min_suite_baton");
				case 2:
					return this.ML.trans("min_suite_cup");
				case 3:
					return this.ML.trans("min_suite_sword");
				case 4:
					return this.ML.trans("min_suite_coin");
			}
		},
		getButtonBorderColor: function(area) {
			return ((this.cardsArea & area) != 0 ? '#00ffffff' : "#00000000");
		},
		toggleCardsAreaVisibility: function(area) {
			if ((this.cardsArea & area) != 0) {
				// Area is set => unset
				var mask = ~area;
				this.cardsArea = (this.cardsArea & mask);
			}
			else {
				// Area bit is unset => set
				this.cardsArea = (this.cardsArea | area);
			}
			this.applyFilter();
			this.scrollCardsDivTop();
		},
		computeCardLayout: function() {
			var marginLeftRight = 2.5;
			var cardWidth = (1.0 * 100 - (this.cardsPerRow + 1) * marginLeftRight - 10) / this.cardsPerRow;
			this.cardLayout = {
				width: cardWidth + "%",
				"margin-left": marginLeftRight + "%",
				"margin-right": marginLeftRight + "%"
			};
			console.log(this.cardsPerRow);
			console.log(this.cardLayout);
		},
		applyFilter: function() {
			this.cardIndexes = [];
			//if ([1, 3].includes(this.cardsArea)) {
			if ((this.cardsArea & 1) != 0) {
				// Add major arcans
				for (var i = 0; i < 22; i++) {
					this.cardIndexes.push(i);
				}
			}
			//if ([2, 3].includes(this.cardsArea)) {
			if ((this.cardsArea & 2) != 0) {
				// Add minor arcans, considering color
				var firstIndex = 22 + 14 * (this.cardsColor - 1);
				var lastIndex = firstIndex + 14;
				for (var i = firstIndex; i < lastIndex; i++) {
					this.cardIndexes.push(i);
				}
			}
		},
		getCard: function(cardIndex, ignoreEmptyCard) {
			if (ignoreEmptyCard) {
				cardIndex += (cardIndex < 22 ? 1 : 2);
			}
			var cardImagesSourceArr = (cardIndex < 23 ? this.cardImages : this.minorCardImages);
			var arrIndex = (cardIndex < 23 ? cardIndex : cardIndex - this.cardImages.length);
			if (arrIndex >= cardImagesSourceArr.length || arrIndex < 0) {
				return cardImagesSourceArr[0];
			}
			return cardImagesSourceArr[arrIndex];
		},
		getImage: function (cardIndex, ignoreEmptyCard) {
			return require("../assets/images/cards/" + this.getCardSet(this.cardPacks, this.goptions.currentCardPackIdId).folder + this.getCard(cardIndex, ignoreEmptyCard).img);
		},
	}
}
</script>

<style>
	.meditation-cards-area {
		position: fixed; overflow-y: auto; left: 80px; bottom: 20px; top: 150px;
	}
	.popupEditLayout {
		background-color: var(--my-wait-popup-back-color);
		color: var(--my-prime-color);
		border: 1px solid grey;
		border-radius: 10px;
		margin-left: 5%;
		margin-right: 5%;
	}
    /* input[type='range']::-webkit-slider-runnable-track {
      background-color: #00ccaa;
	  border-radius: 10px;
    } */
</style>