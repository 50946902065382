<template>
	<div class="container">
		<div @click="toggleVisibility">
			<img ref="cpButton" src="@/assets/images/button-gear.png" :alt="ML.trans('optiuni')" :title="ML.trans('optiuni')">
		</div>
		<div v-show="panelVisible" ref="optionsPanel" tabindex="1" class="dropdownPanel">
			<div>{{ ML.trans('optiuni') }}</div>

			<div class="option-row">
				<span class="itemTitle">{{ ML.trans("limba") }}</span>
				<select v-model="ML.translationOptions.defaultLanguage">
					<option value="ro">Română</option>
					<option value="fr">Français</option>
					<option value="en">English</option>
				</select>
			</div>

			<div class="option-row">
				<span class="itemTitle">{{ ML.trans('set_tarot') }}</span>
				<select v-model="goptions.currentCardPackIdId">
					<option v-for="cardPack in cardPacks" :key="cardPack.id" v-bind:value="cardPack.id">{{ cardPack.name }}</option>
				</select>
			</div>

			<div style="margin-top: 20px;">
				<span class="itemTitle">{{ ML.trans("fundal") }}</span>
				<select v-model="goptions.currentBackground">
					<option v-for="background in tableBackgrounds" :key="background.id" v-bind:value="background.id">{{ background.name }}</option>
				</select>

				<span v-if="isBackgroundSolidColor" style="vertical-align: middle; margin-left: 12px;">
					<input type="color" :value="goptions.backColor" @change="saveColorValue()">
				</span>
				
			</div>

			<div style="margin-top: 20px;">
				<span class="itemTitle">{{ ML.trans('inchide_la_recalculare') }}</span>
				<FunToggleSwitch style="display: inline-block; vertical-align: middle;" type="2" v-model="goptions.autoCloseDatesPanel" :on_state_val="true" :off_state_val="false" />
			</div>

			<div class="option-row">
				<span class="itemTitle">Zoom</span>
				<select v-model="goptions.scale">
					<option v-for="(scaleItem, index) in scales" v-bind:key="index" v-bind:value="scaleItem.value">{{ scaleItem.caption }}</option>
				</select>
			</div>

			<div class="option-row" style="text-align: right;">
				<div style="margin-bottom: 15px;">
					<input type="button" v-if="goptions.serviceWorkerState.deferredInstallPrompt != null" :value="ML.trans('install')" @click="installApp()">
				</div>
				<input type="button" :value="ML.trans('logout')" @click="logout()">
				<br/>
				<br/>
				version {{appVersion}}
			</div>

		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { housesInfo } from "@/mixins/houses";
import { cardImagesInfo } from "@/mixins/cardImagesInfo";
import { cardsDocumentation } from "@/mixins/cardsDocumentation";
import { globalSettings } from "@/mixins/globalSettings";
import { generalDom } from "@/mixins/generalDom";
import FunToggleSwitch from "@/components/controls/FunToggleSwitch.vue"

export default {
	name: "ControlPanel",
	mixins: [housesInfo, cardImagesInfo, cardsDocumentation, globalSettings, generalDom],
	components: { FunToggleSwitch },
	props: ["initdata"],
	data: function() {
		return {
			options: {},
			panelVisible: false,
			scales: [
				{ value: 0.5, caption: "50%" },
				{ value: 0.75, caption: "75%" },
				{ value: 0.8, caption: "80%" },
				{ value: 1, caption: "100%" },
				{ value: 1.2, caption: "120%" },
				{ value: 1.5, caption: "150%" },
				{ value: 1.7, caption: "170%" },
				{ value: 2, caption: "200%" }
			],
			unregisterRouterGuard: null
		};
	},
	created: function() {
		this.options = this.initdata;

		// Filter for Natalia, Serban
		// [...] E din topor, ar trebui ca drepturile sa fie definite pe server si doar stocate in globals, nu definite pe client.
		if (!(['natalia', 'serban'].includes(this.userName))) {
			this.cardPacks = this.cardPacks.filter(cardPack => cardPack.id != "dali");
		}
	},
	mounted: function() {
		// Add the necessary event listeners.
		this.eventListenersAdd("mousedown", this.onMouseDown);
		// Remove the event listeners.
		/*
		this.$once('hook:beforeUnmount', function () {
    		this.eventListenersRemove("mousedown", this.onMouseDown);
		});
		*/
	},
	beforeUnmount: function() {
		this.eventListenersRemove("mousedown", this.onMouseDown);
	},
	computed: {
		...mapState([
			"goptions", "userName"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		isBackgroundSolidColor: function() {
			return this.goptions.currentBackground == "solidColor";
		}
	},
	watch: {
		panelVisible: function() {
			if (this.panelVisible) {
				// Back closes the modal popup instead of navigating back.
				this.unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
					if (this.panelVisible) {
						this.panelVisible = false;
						next(false);
					}
				});
			}
			else {
				// Unregister the route guard to come back to the default browser's "back" button behavior.
				this.unregisterRouterGuard();
			}
		}
	},
	methods: {
		...mapMutations([
				"GLOBAL_StoreUserPassword"
			]),
		installApp: async function() {
			//alert("[TBD] Install app");
            if (this.goptions.serviceWorkerState.deferredInstallPrompt !== null) {
                this.goptions.serviceWorkerState.deferredInstallPrompt.prompt();
                const { outcome } = await this.goptions.serviceWorkerState.deferredInstallPrompt.userChoice;
                if (outcome === 'accepted') {
                    this.goptions.serviceWorkerState.deferredInstallPrompt = null;
                }
            }
		},
		logout: function() {
			// Remove password from the local storage and empty password in global store
			localStorage.removeItem("password");
			this.GLOBAL_StoreUserPassword("");
			// Navigate to the login screen
			this.unregisterRouterGuard();	// In order to enable the navigation to login. Otherwise the navigation is blocked (error) by the route guard.
			this.$router.push({ name: "login", params: {noCredentialsCheck: true } });
		},
		// ==================== [Begin] Event listeners related =======================
		onMouseDown: function(event) {
			if (this.isMouseEventOutsideElement(event, this.$refs["optionsPanel"], [this.$refs["cpButton"]])) {
				this.panelVisible = false;
			}
		},
		// ==================== [End] Event listeners related =======================
		toggleVisibility: function() {
			this.panelVisible = !this.panelVisible;
			if (this.panelVisible) {
				this.$refs.optionsPanel.focus();
			}
		},
		saveColorValue: function() {
			localStorage.backColor = this.goptions.backColor;
		}
	}
}
</script>

<style>
.container {
	position: relative;
	display: inline-block;
}
.dropdownPanel {
	position: absolute;
	top: 100%;
	left: 0px;
	z-index: 100;
	border: 4px solid darkgrey;
	padding: 8px;
	background-color: rgb(211, 211, 211, 0.9);
	max-height: 500px;
	overflow: auto;
	white-space: nowrap;
	border-radius: 15px;
}
.itemTitle {
	margin-right: 8px;
	font-weight: bold;
}
.option-row {
	margin-top: 20px;
}
</style>