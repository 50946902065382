<template>
	<div>

		<div style="position:absolute; height:auto; width:auto; top: 20px; left: 20px;">
			<label style="font-family: 'Times New Roman', Times, serif; font-size: 30px; color: #DDDDDD" @click.prevent="showCardDoc()">{{ ML.trans("referentialul_nasterii") }}</label>		<!-- Şerban Lefter -->
		</div>
		<div id="divMain">
			<!-- Houses -->

			<div v-if="['single','couple','family','diff'].includes(rapSet.calculationMode)">
				<div v-for="(house, index) in rapSet.rapResult.houses" v-bind:key="house.id" v-bind:title="getTooltip(house.id)" v-bind:style="{'left': getDimensionProp(index, 'left'), 'top': getDimensionProp(index, 'top'), 'width': getDimensionProp(index, 'width'), 'transform': getRotateProp(index, 'rotate'), 'box-shadow': (house.highlighted ? '20px 20px rgb(50, 0, 0)' : '') }" style="position:absolute;">
					<img class="card" v-bind:src="getImage(house.id)">
					<div class="cardTopDiv" v-on:click.prevent="onCardClick(house.id, 'image')"></div>
					<div class="cardBottomDiv" v-on:click.prevent="onCardClick(house.id, 'text')"></div>
				</div>
			</div>
			<div v-if="rapSet.calculationMode == 'potential'" style="position: absolute; left: 200px; top: 90px; right: 0px;">
				<div v-for="(house, index) in getSortedHouses" v-bind:key="house.id" style="display: inline-block; margin-bottom: 20px; vertical-align: top;" v-bind:style="{'width': getDimensionProp(index, 'width'), 'box-shadow': (house.highlighted ? '20px 20px rgb(50, 0, 0)' : ''), 'margin-right': (index < 6 ? '20px' : '100%') }" >
					<img class="card" v-bind:src="getImage(house.id)">
					<!-- <div class="cardTopDiv" v-on:click.prevent="onCardClick(house.id, 'image')"></div>
					<div class="cardBottomDiv" v-on:click.prevent="onCardClick(house.id, 'text')"></div> -->
					<div style="font-size: 12px; color: white; background-color: #555555; text-align: center; padding: 4px;">{{ getPotentialHouseName(house) }}</div>
				</div>
			</div>
			<div v-if="rapSet.calculationMode == 'meditation'" style="position: absolute; left: 100px; top: 80px; right: 30px; border: 0px solid red;">
				<Meditation></Meditation>
			</div>

			<div style="position:absolute; height:auto; width:auto; top: 80px; left: 20px; ">
				<!-- Dates panel -->
				<div>
					<DatesPanel :calcnmode="defaults.calculationMode" :initdates="defaults.targetDates" v-on:doCalculate="doCalculate" />
				</div>
				<!-- Control panel -->
				<div style="margin-top: 15px;">
					<ControlPanel />
				</div>
				<div style="margin-top: 15px;">
					<img src="@/assets/images/button-menu.png" @click="showAspects()" :alt="ML.trans('aspecte')" :title="ML.trans('aspecte')">
				</div>
			</div>

			<!-- Aspects -->
			<AspectsPanel v-if="aspectsVisible" @hideAspects="hideAspects" @hilightHouses="hilightHouses" v-bind:houses="rapSet.rapResult.houses" v-bind:cards="cardImages" v-bind:initdata="aspectOptions">
			</AspectsPanel>

			<!-- Big image -->
			<div v-if="bigImageVisible" class="bigCardImageParent">
				<img v-bind:src="getImage(bigImageHouseId)" style="height: 90%; width: auto; opacity: 1;" v-on:click.prevent="hideBigCardImage()" />
			</div>

		</div>

		<CardInfoViewer v-if="cardDocVisible" @hideCardDoc="hideCardDoc()" v-bind:docText="helpText" ></CardInfoViewer>

		<CardHouseInfo v-bind:visible="cardHouseInfoVisible" v-bind:cardText="cardText" v-bind:houseText="houseText" @hideCardHouseInfo="hideCardHouseInfo()"></CardHouseInfo>

	</div>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { housesInfo } from "@/mixins/houses";
import { cardImagesInfo } from "@/mixins/cardImagesInfo";
import { cardsDocumentation } from "@/mixins/cardsDocumentation";
import { housesDocumentation } from "@/mixins/housesDocumentation";
import CardInfoViewer from "@/components/CardInfoViewer";
import CardHouseInfo from "@/components/CardHouseInfo";
import AspectsPanel from "@/components/AspectsPanel";
import ControlPanel from "@/components/ControlPanel";
import DatesPanel from "@/components/DatesPanel";
import Meditation from "@/components/Meditation";
import { globalSettings } from "@/mixins/globalSettings";
import ky from "ky";

export default {
	name: "cardsTable",
	mixins: [housesInfo, cardImagesInfo, cardsDocumentation, housesDocumentation, globalSettings],
	components: { CardInfoViewer, AspectsPanel, ControlPanel, DatesPanel, CardHouseInfo, Meditation },
	data: function() { return ({
		offsetX: 0,
		offsetY: 0,
		helpText: "yyy",
		bigImageHouseId: 0,
		cardDocVisible: false,
		bigImageVisible: false,
		cardHouseInfoVisible: false,
		aspectsVisible: false,
		aspectOptions: {
			currentAspect: 0,
			highlightedHouses: []
		},
		escapeKeyCallback: null,
		rapSet: null,
		defaults: {
			calculationMode: "single",
			targetDates: ["6-3-1963"]
		},
		cardText: "",
		houseText: "",
		unregisterAspectsRouterGuard: null,
		unregisterBigImageRouterGuard: null,
		unregisterCardHouseInfoRouterGuard: null
	})},

	created: function () {
		this.goptions.currentBackground = localStorage.tableBackground;
		this.goptions.backColor = localStorage.backColor;

		// Set the initial date to the current date
		this.defaults.targetDates[0] = this.dateToIsoStr(new Date(), "DMY", "D", "-");

		this.doCalculate({ calculationMode: this.defaults.calculationMode, dates: this.defaults.targetDates });

		this.aspectOptions.currentAspect = this.enums.aspects.Banda;

		this.setBackgroundImage();

		window.addEventListener("keydown", this.onKeydown);
		//var winsize = windowSize();
		//alert(winsize.width);
	},
	unmounted: function() {
		window.removeEventListener("keydown", this.onKeydown);
	},
	watch: {
		"currentBackground": function() {
			console.log("url('" + process.env.BASE_URL + "/" + this.currentBackground + "')");
			localStorage.tableBackground = this.currentBackground;
			this.setBackgroundImage();
		},
		backSolidColor: function() {
			this.setBackgroundImage();
		},
		currentLanguage: function() {
			localStorage.language = this.currentLanguage;
		},
		aspectsVisible: function() {
			if (this.aspectsVisible) {
				// Back closes the modal popup instead of navigating back.
				this.unregisterAspectsRouterGuard = this.$router.beforeEach((to, from, next) => {
					if (this.aspectsVisible) {
						this.aspectsVisible = false;
						next(false);
					}
				});
			}
			else {
				// Unregister the route guard to come back to the default browser's "back" button behavior.
				this.unregisterAspectsRouterGuard();
			}
		},
		bigImageVisible: function() {
			if (this.bigImageVisible) {
				// Back closes the modal popup instead of navigating back.
				this.unregisterBigImageRouterGuard = this.$router.beforeEach((to, from, next) => {
					if (this.bigImageVisible) {
						this.bigImageVisible = false;
						next(false);
					}
				});
			}
			else {
				// Unregister the route guard to come back to the default browser's "back" button behavior.
				this.unregisterBigImageRouterGuard();
			}
		},
		cardHouseInfoVisible: function() {
			if (this.cardHouseInfoVisible) {
				// Back closes the modal popup instead of navigating back.
				this.unregisterCardHouseInfoRouterGuard = this.$router.beforeEach((to, from, next) => {
					if (this.cardHouseInfoVisible) {
						this.cardHouseInfoVisible = false;
						next(false);
					}
				});
			}
			else {
				// Unregister the route guard to come back to the default browser's "back" button behavior.
				this.unregisterCardHouseInfoRouterGuard();
			}
		}
	},
	computed: {
		...mapState([
			"serverPassword", "userName", "userPassword", "goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		visibility: {
			get: function () {
				return this.visible ? "normal" : "none";
			}
		},
		currentBackground: function() {
			return this.goptions.currentBackground;
		},
		isBackgroundSolidColor: function() {
			return this.currentBackground == "solidColor";
		},
		backSolidColor: function() {
			return this.goptions.backColor;
		},
		currentLanguage: function() {
			return this.ML.translationOptions.defaultLanguage;
		},
		getSortedHouses: function() {
			return this.rapSet.rapResult.houses
				.filter(house => house.cardIndex > 0)
				.sort((house1, house2) => {return house1.id - house2.id});
		}


/*
		aspectsPanelSize: {
			get: function() {
				// Rigt coordinate of c12
				var house = this.getHouse(12);
				var x = this.getDimensionProp(this.getHouseIndex(12), "left", true) + this.getDimensionProp(this.getHouseIndex(12), "width", true) + 20;
				// Top coordinate of c8
				house = this.getHouse(8);
				var height = this.getDimensionProp(this.getHouseIndex(13), "top", true);
				return { left: x, top: 0, width: 0, height: height };
			}
		}
*/
	},
	methods: {
		getPotentialHouseName: function(house) {
			switch(house.id) {
				case 1:
					return this.ML.trans("afectiv");
				case 2:
					return this.ML.trans("uman");
				case 3:
					return this.ML.trans("de_vindecare");
				case 4:
					return this.ML.trans("creativ");
				case 5:
					return this.ML.trans("perceptiv");
				case 6:
					return this.ML.trans("de_intelepciune");
				default:
					return this.ML.trans("de_supravietuire");
			}
		},
		doCalculate: function(calcData) {
			// Initialize the rap set.
			var rapSet = this.generateRapSet([], calcData.calculationMode);
			// Consider only the significant dates.
			calcData.dates = this.getSignificantDates(calcData.dates, rapSet.calculationMode);
			// Create the raps and add them to the rap set.
			calcData.dates.forEach(dateStr => {
				var date = this.stringToDate(dateStr);
				var rap = this.generateRap(date.getFullYear(), date.getMonth() + 1, date.getDate());
				rapSet.rapSources.push(rap);
			});
			this.rapSet = rapSet;
			this.recalculate();
		},
		onKeydown: function(event) {
			// Manage the Esc key down.
			if ((event.keyCode == 27) && (this.escapeKeyCallback != null)) {
				this.escapeKeyCallback();
				this.escapeKeyCallback = null;
			}
		},
		showBigCardPicture: function(houseId) {
			this.escapeKeyCallback = this.hideBigCardImage;
			this.bigImageHouseId = houseId;
			this.bigImageVisible = true;
		},
		hideBigCardImage: function(event) {
			this.bigImageVisible = false;
		},
		setBackgroundImage: function() {
			if (this.isBackgroundSolidColor) {
				document.body.style.backgroundImage = "none";
				document.body.style.backgroundColor = this.goptions.backColor;
				return;
			}
			document.body.style.backgroundImage = (this.currentBackground != "none" ? `url(${require("@/assets/images/background/" + this.currentBackground)})` : "none");
		},
		hilightHouses: function(options) {
			this.hideAspects();
			// Remove any current highlight.
			this.rapSet.rapResult.houses.forEach(house => {
				house.highlighted = false;
			});
			// Highlight the houses in array.
			options.highlightedHouses.forEach(housesArray => {
				housesArray.forEach(house => {
					var foundHouse = this.getHouse(this.rapSet.rapResult, house.id);
					foundHouse.highlighted = true;
				});
			});
			this.aspectOptions = options;
		},
		showCardDoc: function() {
			this.cardDocVisible = true;
		},
		hideCardDoc: function() {
			this.cardDocVisible = false;
		},
		hideCardHouseInfo: function() {
			this.cardHouseInfoVisible = false;
		},
		showAspects: function() {
			this.escapeKeyCallback = this.hideAspects;
			this.aspectsVisible = true;
		},
		hideAspects: function() {
			this.aspectsVisible = false;
		},
		fontSize: function () {
		},
		onCardClick: function (houseId, action) {
			// Get the current card in the house.
			var house = this.getHouse(this.rapSet.rapResult, houseId);
			// If there are highlights already in place => remove them and return.
			if (house.highlighted) {
				this.removeHighlights();
				return;
			}

			switch (action) {
				case "image":
					// Show big card image.
					this.showBigCardPicture(houseId);
					break;
				case "text":
					//this.$refs.cardHousDetailsPanel.style.width = "100%";
					var cardImagesSourceArr = houseId < 14 ? this.cardImages : this.minorCardImages;
					var cardId = cardImagesSourceArr[house.cardIndex].id;
					var docText = "";
					for (var i = 0; i < this.cardsDocument.length; i++) {
						if (this.cardsDocument[i].id == cardId) {
							docText = this.cardsDocument[i].doc;
							this.cardText = docText;
							break;
						}
					}
					var houseDoc = this.housesDocument.find(el => el.id == houseId);
					this.houseText = houseDoc != null ? "<b>" + houseDoc.title + "</b><hr/>" + houseDoc.doc : "???";
					this.cardHouseInfoVisible = true;
					break;

				case "text_obsolete":
					// Get the doc text from docs and show text control.
					var cardImagesSourceArr = houseId < 14 ? this.cardImages : this.minorCardImages;
					var cardId = cardImagesSourceArr[house.cardIndex].id;
					var docText = "";
					for (var i = 0; i < this.cardsDocument.length; i++) {
						if (this.cardsDocument[i].id == cardId) {
							docText = "<b>" + this.cardsDocument[i].title + "</b><hr/>" + this.cardsDocument[i].doc;
							this.helpText = docText;
							this.showCardDoc();
							break;
						}
					}
					break;
			}
		},
		closeNav: function() {
			this.$refs.cardHousDetailsPanel.style.width = "0%";
		},
		removeHighlights: function() {
			this.rapSet.rapResult.houses.forEach(h => {
				h.highlighted = false;
			});
		},
		hasHighlights: function() {
			return (this.rapSet.rapResult.houses.findIndex(house => house.highlighted) != -1)
		},
		getCardDoc: function (cardId) {
			// Get the current
		},
		recalculate: function () {
			// Check if the user password is still valid.
			ky(this.apiUrl + "/CheckUserPassword", {
				method: "GET",
				searchParams: {
					user: this.userName,
					password: this.userPassword
				},
				cache: "no-cache",
				timeout: 5000,
				retry: 1
				})
				.json()
				.then(responseJson => {
					var isPasswordValid = responseJson.valid;
					if (!isPasswordValid) {
						// Redirect to the login page.
						this.$router.push({ name: "login" });
					}
					else {
						// Ssplit the date.
						this.removeHighlights();
						this.calculateReferential();
					}
				})
				.catch(error => {
					alert("Error: " + error.message);
				});
		},
		getTooltip: function (houseId) {
			var cardImagesSourceArr = houseId < 14 ? this.cardImages : this.minorCardImages;

			var house = this.getHouse(this.rapSet.rapResult, houseId);
			return house.title + "\n" + cardImagesSourceArr[house.cardIndex].name + "\nArcana " + house.cardIndex;
		},
		getDimensionProp: function (index, propname, returnNumber) {
			var val = this.goptions.scale * this.rapSet.rapResult.houses[index][propname];
			switch(propname) {
				case "left":
					val += this.offsetX;
					break;
				case "top":
					val += this.offsetY;
					break;
			}
			val +=  (returnNumber ? 0 : "px");
			return val;
		},
		getRotateProp: function (index, propname) {
			var val = "rotate(" + this.rapSet.rapResult.houses[index][propname] + "deg)";
			return val;
		},
		getImage: function (houseId) {
			if (this.rapSet.calculationMode == "potential") {
				// In "potential" calculation mode, all the houses present cards from the major arcans.
				cardImagesSourceArr = this.cardImages;
			}
			else {
				// In all the other calculation modes, the houses 1...13 present cards from the 
				// major arcans, and only the house 14 presents its card from the minor arcans.
				var cardImagesSourceArr = houseId < 14 ? this.cardImages : this.minorCardImages;
			}

			//console.log("../assets/images/cards/" + this.getCardSet(this.cardPacks, this.goptions.currentCardPackIdId).folder + cardImagesSourceArr[(this.getHouse(this.rapSet.rapResult, houseId)).cardIndex].img);
			return require("../assets/images/cards/" + this.getCardSet(this.cardPacks, this.goptions.currentCardPackIdId).folder + cardImagesSourceArr[(this.getHouse(this.rapSet.rapResult, houseId)).cardIndex].img);
		},
		getHouse: function (referential, id) {
			for (var i = 0; i < referential.houses.length; i++) {
				if (referential.houses[i].id == id) {
					return referential.houses[i];
				}
			}
			return null;
		},
		getHouseIndex: function(houseId) {
			var index = this.houses.findIndex(house => house.id == houseId);
			return index;
		},
		sumRapSetHouseValues: function(rapSet, houseId) {
			var retVal = 0;
			for (var i = 0; i < rapSet.rapSources.length; i++) {
				retVal += rapSet.rapSources[i].houses[this.getHouseIndex(houseId)].cardIndex;
			}
			return retVal;
		},
		calculateReferential: function () {
			// Calculate all the individual referentials in the set.
			for (var i = 0; i < this.rapSet.rapSources.length; i++) {
				var rap = this.rapSet.rapSources[i];
				// Calculate for all houses.
				for (var id = 1; id <= 14; id++) {
					this.calculateHouse(rap, id);
				}
			}


			var rapResult = this.rapSet.rapResult;

			// Calculate the final result and store it in the set's rapResult.
			switch(this.rapSet.calculationMode) {
				case "single":
					// Set the rapResult to the first rap in set.
					for (var i = 0; i < rapResult.houses.length; i++) {
						rapResult.houses[i] = this.rapSet.rapSources[0].houses[i];
					}

					//this.getHouse(rapResult, 2).cardIndex = this.reduce(this.sumRapSetHouseValues(this.rapSet, 2), 22);
					this.rapSet.rapResult = {...this.rapSet.rapResult};
					break;
				case "diff":
					// Calculate the result based on the first rap in set.
					this.getHouse(rapResult, 1).cardIndex = this.reduce(22 - this.getHouse(this.rapSet.rapSources[0], 1).cardIndex, 22);
					this.getHouse(rapResult, 2).cardIndex = this.reduce(22 - this.getHouse(this.rapSet.rapSources[0], 2).cardIndex, 12);
					this.getHouse(rapResult, 3).cardIndex = this.reduce(22 - this.getHouse(this.rapSet.rapSources[0], 3).cardIndex, 22);
					this.getHouse(rapResult, 4).cardIndex = this.reduce(22 - this.getHouse(this.rapSet.rapSources[0], 4).cardIndex, 22);
					// Calculate the remaining houses - 5...14
					for (var id = 5; id <= 14; id++) {
						this.calculateHouse(rapResult, id);
					}

					break;
				case "potential":
					// {{ this.rapSet.rapSources[0] }} contains the already calculated "classic" referential.
					// Now initialize the differential calculation in a local rap structure, considering the same date elements from the calculated rap (this.rapSet.rapSources[0]).
					var rapDifferential = this.generateRap(this.rapSet.rapSources[0].year, this.rapSet.rapSources[0].month, this.rapSet.rapSources[0].day);
					// Calculate the local differential structure.
					this.getHouse(rapDifferential, 1).cardIndex = this.reduce(22 - this.getHouse(this.rapSet.rapSources[0], 1).cardIndex, 22);
					this.getHouse(rapDifferential, 2).cardIndex = this.reduce(22 - this.getHouse(this.rapSet.rapSources[0], 2).cardIndex, 12);
					this.getHouse(rapDifferential, 3).cardIndex = this.reduce(22 - this.getHouse(this.rapSet.rapSources[0], 3).cardIndex, 22);
					this.getHouse(rapDifferential, 4).cardIndex = this.reduce(22 - this.getHouse(this.rapSet.rapSources[0], 4).cardIndex, 22);
					for (var id = 5; id <= 14; id++) {
						this.calculateHouse(rapDifferential, id);
					}

					// The house 8 must be ignored => Replace the card in house 8 with a minor card. Any minor card will be filtered.
					this.getHouse(this.rapSet.rapSources[0], 8).cardIndex = 30;
					this.getHouse(rapDifferential, 8).cardIndex = 30;
					
					// Continue with the "differential" stuff
					var arrRapIndexes = this.rapSet.rapSources[0].houses.map(house => house.cardIndex);
					var arrDiffIndexes = rapDifferential.houses.map(house => house.cardIndex);
					var arrAllReferredCards = arrRapIndexes.concat(arrDiffIndexes);
					var distinctReferredCards = [...new Set(arrAllReferredCards)];

					// Eliminate the minor cards (the one calculated for the house 14).
					distinctReferredCards = distinctReferredCards.filter(cardIndex => cardIndex <= 22);

					// Cumulate the carts that don't appear neither in the in "this.rapSet.rapSources[0]", or in the temporary differential structure.
					var potentialCardIndexes = [];
					for(var i = 1; i < this.cardImages.length; i++) {
						//console.log(this.cardImages[i].name);
						if (!distinctReferredCards.includes(i)) {
							potentialCardIndexes.push(i);
						}
					}
					// Render the "potential" cards
					var maxRenderedCards = 14;
					for(var i = 0; i < Math.min(potentialCardIndexes.length, maxRenderedCards); i++) {
						this.getHouse(rapResult, i + 1).cardIndex = potentialCardIndexes[i];
					}
					break;
				case "couple":
					// Specific calculations for the houses 1...4
					this.getHouse(rapResult, 1).cardIndex = this.reduce(this.sumRapSetHouseValues(this.rapSet, 1), 22);
					this.getHouse(rapResult, 2).cardIndex = this.reduce(this.sumRapSetHouseValues(this.rapSet, 2), 22);
					this.getHouse(rapResult, 3).cardIndex = this.reduce(this.sumRapSetHouseValues(this.rapSet, 3), 22);
					this.getHouse(rapResult, 4).cardIndex = this.reduce(this.sumRapSetHouseValues(this.rapSet, 4), 22);
					// Calculate the remaining houses - 5...14
					for (var id = 5; id <= 14; id++) {
						this.calculateHouse(rapResult, id);
					}

					break;
				case "family":
					this.getHouse(rapResult, 1).cardIndex = this.reduce(this.sumRapSetHouseValues(this.rapSet, 1), 22);
					this.getHouse(rapResult, 2).cardIndex = this.reduce(this.sumRapSetHouseValues(this.rapSet, 2), 12);
					this.getHouse(rapResult, 3).cardIndex = this.reduce(this.sumRapSetHouseValues(this.rapSet, 3), 22);
					this.getHouse(rapResult, 4).cardIndex = this.reduce(this.sumRapSetHouseValues(this.rapSet, 4), 22);
					// Calculate the remaining houses - 5...14
					for (var id = 5; id <= 14; id++) {
						this.calculateHouse(rapResult, id);
					}

					break;
			}
		},
		calculateHouse: function (referential, houseId) {
			var house = this.getHouse(referential, houseId);
			switch (house.id) {
				case 1:
					house.cardIndex = this.reduce(referential.day, 22);
					break;
				case 2:
					house.cardIndex = referential.month;
					break;
				case 3:
					house.cardIndex = this.reduce(referential.year, 22);
					break;
				case 4:
					house.cardIndex = this.reduce(referential.day + referential.month + referential.year, 22);
					break;
				case 5:
					house.cardIndex = this.reduce(this.getHouse(referential, 1).cardIndex + this.getHouse(referential, 2).cardIndex + this.getHouse(referential, 3).cardIndex + this.getHouse(referential, 4).cardIndex, 22);
					break;
				case 6:
					house.cardIndex = this.reduce(this.getHouse(referential, 1).cardIndex + this.getHouse(referential, 2).cardIndex, 22);
					break;
				case 7:
					var val = this.reduce(Math.abs(this.getHouse(referential, 3).cardIndex - this.getHouse(referential, 2).cardIndex), 22);
					house.cardIndex = (val == 0 ? 22 : val);
					break;
				case 8:
					var currentDate = new Date();
					var currentYear = currentDate.getFullYear();
					house.cardIndex = this.reduce(this.reduce(currentYear, 9, [11, 22]) + this.getHouse(referential, 6).cardIndex, 22);
					break;
				case 9:
					house.cardIndex = this.reduce(this.getHouse(referential, 6).cardIndex + this.getHouse(referential, 7).cardIndex, 22);
					break;
				case 10:
					house.cardIndex = (this.getHouse(referential, 9).cardIndex != 22 ? Math.abs(22 - this.getHouse(referential, 9).cardIndex) : house.cardIndex = 22);
					break;
				case 11:
					house.cardIndex = this.reduce(this.getHouse(referential, 7).cardIndex + this.getHouse(referential, 3).cardIndex + this.getHouse(referential, 10).cardIndex, 22);
					break;
				case 12:
					house.cardIndex = this.reduce(this.getHouse(referential, 6).cardIndex + this.getHouse(referential, 2).cardIndex + this.getHouse(referential, 4).cardIndex, 22);
					break;
				case 13:
					house.cardIndex = this.reduce(this.getHouse(referential, 9).cardIndex + this.getHouse(referential, 2).cardIndex + this.getHouse(referential, 5).cardIndex + this.getHouse(referential, 4).cardIndex + this.getHouse(referential, 11).cardIndex + this.getHouse(referential, 3).cardIndex + this.getHouse(referential, 5).cardIndex + this.getHouse(referential, 1).cardIndex + this.getHouse(referential, 12).cardIndex, 22);
					break;
				case 14:
					var house5Sum = this.getHouse(referential, 1).cardIndex + this.getHouse(referential, 2).cardIndex + this.getHouse(referential, 3).cardIndex + this.getHouse(referential, 4).cardIndex;
					house.cardIndex = this.reduce(house5Sum, 56);
					break;
			}
		},
		windowSize: function() {
			var width = window.innerWidth
			|| document.documentElement.clientWidth
			|| document.body.clientWidth;

			var height = window.innerHeight
			|| document.documentElement.clientHeight
				|| document.body.clientHeight;

			return { "width": width, "height": height };
		}
	}

}
</script>

<style>
	div.bigCardImageParent {
	display:flex;
	position:fixed;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	justify-content:center;
	align-items:center;
	background-color: rgba(0,0,0,0.5);
	z-index: 101;
	}
	.space-before-x {
		margin-left: 10px;;
	}
	.card {
		width: 100%;
		height: auto;
	}
	.cardTopDiv {
		position: absolute;
		top: 0px;
		left: 0px;
		height:50%;
		width: 100%;
	}
	.cardBottomDiv {
		position: absolute;
		top: 50%;
		left: 0px;
		height:50%;
		width: 100%;
	}
</style>
