<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper" align="center">
				<div class="modal-container" style="opacity: 0.9; display:table-cell; text-align:left; border-radius: 10px; background-color: rgb(250, 255, 230);">





					<div id="divDoc" style="padding: 10px; border: 1px solid #AAAAAA; margin: 10px;">
						<div id="aspectSelect" style="margin-bottom: 10px; text-align: center;">
							<span>{{ ML.trans("aspect") }}: </span>
							<select v-model="options.currentAspect">
								<option v-for="aspect in enumPresenter.aspects" :key="aspect.value" v-bind:value="aspect.value">{{ ML.trans(aspect.ml) }}</option>
							</select>
						</div>

						<div id="hline" style="border: 1px solid #AAAAAA; margin-top: 10px; margin-bottom: 10px;"></div>

						<div id="aspectContentDiv" style="overflow-y: auto; max-width: 400px; max-height: 550px; width: 600px;">
							<!-- Banda -->
							<div v-if="options.currentAspect == this.enums.aspects.Banda" style="text-align: center;">
								<img v-bind:src="bandaGetImageSrc()" />
								<br />
								<label>{{ bandaGetCard().name }}</label>
							</div>

							<!-- Dialectica -->
							<div v-if="options.currentAspect == this.enums.aspects.Dialectica">
								{{ ML.trans('perechi') }}:
								<div style="padding-top: 10px; padding-bottom: 10px;">
									<input type="button" v-for="pair in cardSets2" v-bind:value="housesSetCardName(pair)" v-on:click.prevent="highlightHousess(pair)" style="font-size: 18px;" />
								</div>
							</div>

							<!-- Dialectica -->
							<div v-if="options.currentAspect == this.enums.aspects.Oglinzile">
								[TBD]
							</div>
						</div>

						<div id="hline" style="border: 1px solid #AAAAAA; margin-top: 10px; margin-bottom: 10px;"></div>

						<div id="buttonsDiv" style="text-align: center;">
							<input type="button" :value="ML.trans('inchide')" v-on:click.prevent="doClose()" style="font-size: 18px;" />
						</div>
					</div>





				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { globalSettings } from "@/mixins/globalSettings";
import { cardImagesInfo } from "@/mixins/cardImagesInfo";

export default {
	name: "AspectsPanel",
	props: ["houses", "cards", "initdata"],
	mixins: [globalSettings, cardImagesInfo],
	data: function() {
		return {
			options: {}
		};
	},
	created: function() {
		this.options = this.initdata;
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
		cardSets2: function() {
			return this.getCardSets(2);
		}
	},
	methods: {
		doClose: function() {
			this.$emit("hideAspects", this.options);
		},
		highlightHousess: function(houseArray) {
			this.options.highlightedHouses = houseArray;
			this.$emit("hilightHouses", this.options);
		},
		bandaGetImageSrc: function() {
			var cardIndex = this.reduce(this.getHouse(6).cardIndex + this.getHouse(5).cardIndex + this.getHouse(10).cardIndex, 22);
			return this.getImageSrc(cardIndex);
		},
		bandaGetCard: function() {
			var cardIndex = this.reduce(this.getHouse(6).cardIndex + this.getHouse(5).cardIndex + this.getHouse(10).cardIndex, 22);
			return this.cards[cardIndex];
		},
		getHouse: function (id) {
			for (var i = 0; i < this.houses.length; i++) {
				if (this.houses[i].id == id) {
					return this.houses[i];
				}
			}
			return null;
		},
		getImageSrc: function (cardIndex) {
			return require("../assets/images/cards/" + this.getCardSet(this.cardPacks, this.goptions.currentCardPackIdId).folder + this.cards[cardIndex].img);
		},
		getCardSets: function(setSize) {
			var retArr = [];
			// Get the distinct card numbers in houses.
			var uniqueCardNumbers = this.getDistinctArrayFieldValues(this.houses, "cardIndex");
			//alert(uniqueCardNumbers.toString());
			// For each unique card number, count the number of elements in houses. If two => qualified
			
			uniqueCardNumbers.forEach(distinctVal => {
				var arrSet = [];
				this.houses.forEach(house => {
					if (house.cardIndex == distinctVal) {
						arrSet.push([house]);
					}
				});
				if (arrSet.length == setSize) {
					retArr.push(arrSet);
				}
			});
			
			return retArr;
		},
		getDistinctArrayFieldValues: function(arr, distinctFieldName) {
			var retArr = [];
			arr.forEach(element => {
				if (!retArr.includes(element[distinctFieldName])) {
					retArr.push(element[distinctFieldName]);
				}
			});
			return retArr;
		},
		houseSetTitle: function(housesArr) {
			return housesArr[0][0].title;
		},
		housesSetCardName: function(housesArr) {
			var cardIndex = housesArr[0][0].cardIndex;
			var card = this.cards[cardIndex];
			return card.name;
		}

	

	}
}
</script>

<style>

</style>
