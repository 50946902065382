import { createRouter, createWebHashHistory } from 'vue-router'

import Main from "../views/CardsTable.vue"
import LoginUserNamePassword from "../views/LoginUserNamePassword"

const routes = [
	{
		path: "/",
		name: "default",
		component: LoginUserNamePassword
	},
	{
		path: '/CardsTable',
		name: 'cardsTable',
		component: Main
	},
	{
		path: "/login",
		name: "login",
		component: LoginUserNamePassword
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
