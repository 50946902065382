<template>
	<div class="container">
		<div>
			<img ref="datesButton" src="@/assets/images/button-dates.png" style= "vertical-align: middle;" alt="Date" title="Date" @click="togglePanelVisibility()">
			<div v-if="calculationModeVisible" style="display: inline-block; vertical-align: middle; color: white; margin-left: 10px;">
				<div class="rapItem" @click="startCalculate()">
					<span>{{  ML.trans(getMlidFromId(calculationModes, mostRecentCalculationMode, '')) }}</span>
					<img v-if="currentlyDisplayedRapItem == '*'" class="rapItemStar" src="@/assets/images/star nb.png" />
				</div>
				<div class="rapItem" v-for="date in getSignificantDates(mostRecentCorrectDates, mostRecentCalculationMode)" @click="displayIndividualRap(date)">
					<span>{{date}}</span>
					<img v-if="date == currentlyDisplayedRapItem" class="rapItemStar" src="@/assets/images/star nb.png" />
				</div>
			</div>
		</div>
		<div ref="datesEditPanel" class="datesEditPanel" v-show="panelVisible">
			<img src="@/assets/images/button-long-go.png" v-on:click.prevent="startCalculate()" :alt="ML.trans('compute')" :title="ML.trans('compute')">
			<div style="margin-top: 8px;">
				<select v-model="calculationMode">
					<option v-for="calcMode in calculationModes" :key="calcMode.id" v-bind:value="calcMode.id">{{ ML.trans(getMlidFromId(calculationModes, calcMode.id, '')) }}</option>
				</select>
			</div>
			<div class="panel-row">
				<div v-for="date, index in targetDates" style="margin-top: 5px;">
					<input type="text" size="10" v-if="inputDateVisible(index)" class="centerVertically" v-bind:class="{error: !isDateValid(targetDates[index])}" v-model="targetDates[index]" placeholder="?" v-on:keydown.enter="onDateTextBoxEnterPressed" />
					<img v-if="inputDateVisible(index) && calculationMode == 'family' && index > 1" src="@/assets/images/button-minus.png" @click="deleteDate(index)" alt="Sterge" title="Sterge" class="centerVertically">
				</div>
			</div>
			<div class="panel-row" style="text-align: right;">
				<img v-if="calculationMode == 'family'" src="@/assets/images/button-plus.png" @click="addDate()" alt="Adauga" title="Adauga">
			</div>
		</div>
	</div>
</template>	

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { housesInfo } from "@/mixins/houses";
import { cardImagesInfo } from "@/mixins/cardImagesInfo";
import { cardsDocumentation } from "@/mixins/cardsDocumentation";
import { globalSettings } from "@/mixins/globalSettings";
import ky from "ky";
import { generalDom } from "@/mixins/generalDom";

export default {
	name: "DatesPanel",
	mixins: [housesInfo, cardImagesInfo, cardsDocumentation, globalSettings, generalDom],
	props: {
		initdates: {
			default: ['6-3-1963']
		},
		calcnmode: {
			default: "single" 
		}
	},
	data: function() {
		return {
			calculationMode: "",
			targetDates: [],
			panelVisible: false,
			mostRecentCorrectDates: [],
			mostRecentCalculationMode: null,
			currentlyDisplayedRapItem: "*",
			unregisterRouterGuard: null,
			calculationModeVisible: false
		};
	},
	created: function() {
		this.calculationMode = this.calcnmode;
		this.setCalcModeAreaVisibility();
		this.targetDates = [...this.initdates];
		this.mostRecentCorrectDates = [...this.initdates];
		this.mostRecentCalculationMode = this.calcnmode;
		if (this.targetDates.length < 1) {
			this.targetDates.push("");	// Ensure at least one date.
		}
		// Add supplementary date if not single and if < 2 dates.
		if ((this.calculationMode != "single") && (this.targetDates.length < 2)) {
			this.targetDates.push("");
		}
	},
	mounted: function() {
		// Add the necessary event listeners.
		this.eventListenersAdd("mousedown", this.onMouseDown);
		// Remove the event listeners.
		/*
		this.$once('hook:beforeUnmount', function () {
    		this.eventListenersRemove("mousedown", this.onMouseDown);
		});
		*/
	},
	beforeUnmount: function() {
		// Remove the event listeners.
   		this.eventListenersRemove("mousedown", this.onMouseDown);
	},
	computed: {
		...mapState([
			"goptions"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		})
	},
	watch: {
		calculationMode: function() {
			if ((this.calculationMode != "single") && this.targetDates.length < 2) {
				for(var i = this.targetDates.length; i < 2; i++) {
					this.targetDates.push("");
				}
			}
		},
		panelVisible: function() {
			if (this.panelVisible) {
				// Back closes the modal popup instead of navigating back.
				this.unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
					if (this.panelVisible) {
						this.panelVisible = false;
						next(false);
					}
				});
			}
			else {
				// Unregister the route guard to come back to the default browser's "back" button behavior.
				this.unregisterRouterGuard();
			}
		}
	},
	methods: {
		togglePanelVisibility: function() {
			this.panelVisible = !this.panelVisible;
		},
		addDate: function() {
			this.targetDates.push("");
		},
		deleteDate: function(index) {
			this.targetDates.splice(index, 1);
		},
		inputDateVisible: function(dateIndex) {
			switch(this.calculationMode) {
				case "single":
					return (dateIndex < 1);
					break;
				case "diff":
					return (dateIndex < 1);
					break;
				case "couple":
					return (dateIndex < 2);
					break;
				case "family":
					return true;
					break;
				case "potential":
					return (dateIndex < 1);
					break;
				case "meditation":
					return false;
			}
		},
		isDateValid: function(dateStr) {
			return this.stringToDate(dateStr) != null;
		},
		getDisplayedDates: function() {
			return this.getSignificantDates(this.mostRecentCorrectDates, this.calculationMode).join();
		},
		getDisplayedDatesHtml: function() {
			var s = this.getSignificantDates(this.mostRecentCorrectDates, this.calculationMode).join();
			return s.replace(",", "<br />");
		},
		areAllSignificantDatesValid: function() {
			//isDateValid(targetDates[index]
			var checkDatesCount = 0;
			switch(this.calculationMode) {
				case "single":
					checkDatesCount = 1;
					break;
				case "diff":
					checkDatesCount = 1;
					break;
				case "couple":
					checkDatesCount = 2;
					break;
				case "family":
					checkDatesCount = this.targetDates.length;
					break;
				case "potential":
					checkDatesCount = 1;
					break;
				case "meditation":
					checkDatesCount = 0;
					break;
			}
			var datesValid = true;
			for (var i=0; i < checkDatesCount; i++) {
				if (!this.isDateValid(this.targetDates[i])) {
					datesValid = false;
					break;
				}
			}
			return datesValid;
		},
		startCalculate: function() {
			if (!this.areAllSignificantDatesValid()) {
				alert(this.ML.trans("date_incorecte"));
				return;
			}
			// Engage!
			this.mostRecentCorrectDates = [...this.targetDates];
			this.mostRecentCalculationMode = this.calculationMode;
			this.currentlyDisplayedRapItem = !(["single", "diff", "potential"].includes(this.calculationMode)) ? "*" : this.mostRecentCorrectDates;
			if (this.goptions.autoCloseDatesPanel) {
				this.panelVisible = false;
			}
			this.setCalcModeAreaVisibility();
			this.$emit("doCalculate", {calculationMode: this.calculationMode, dates: [...this.targetDates]});
		},
		displayIndividualRap: function(dateStr) {
			this.currentlyDisplayedRapItem = this.mostRecentCorrectDates.find(element => element == dateStr);
			this.$emit("doCalculate", {calculationMode: "single", dates: [dateStr]});
		},
		// ==================== [Begin] Event listeners related =======================
		onMouseDown: function(event) {
			if (this.isMouseEventOutsideElement(event, this.$refs["datesEditPanel"], [this.$refs["datesButton"]])) {
				this.panelVisible = false;
			}
		},
		setCalcModeAreaVisibility: function() {
			this.calculationModeVisible = this.calculationMode != "meditation";
		},
		// ==================== [End] Event listeners related =======================
		onDateTextBoxEnterPressed: function(event) {
			this.startCalculate();
		}
	}
}
</script>

<style>
.container {
	position: relative;
	border: "1px solid white";
}
.datesEditPanel {
	position: absolute;
	top: 100%;
	left: 0px;
	z-index: 100;
	border: 4px solid darkgrey;
	padding: 8px;
	background-color: rgb(211, 211, 211, 0.9);
	max-height: 500px;
	overflow: auto;
	white-space: nowrap;
	border-radius: 15px;
}
.error {
	border: 3px solid red;
}
.rapItem {
	cursor: pointer;
	margin: 8px 0px 8px 0px;
}
.rapItemStar {
	margin-left: 10px;
}
.centerVertically {
	vertical-align: middle;
}
.itemTitle {
	margin-right: 8px;
	font-weight: bold;
}
.panel-row {
	margin-top: 15px;
}
</style>
