<template>
	<router-view />
</template>

<style>
</style>

<script>
import { mapState, mapMutations, Store } from 'vuex'
import { globalSettings } from "@/mixins/globalSettings";

export default {
	name: "app",
	mixins: [globalSettings],
	data: function() {
		return {
		}
	},
	computed: {
		...mapState([
			"goptions", "userName"
		]),
		...mapState({
			ML: state => state.MultiLanguage.translations,
		}),
	},
	created: function() {
		var that = this;
		if (this.isProductionEnvironment) {
			if ('serviceWorker' in navigator) {
				// Register the service worker
				window.addEventListener('load', () => {
					//navigator.serviceWorker.register("./" + (this.isProductionEnvironment ? "ui/dist/" : "dist/" ) + "service-worker.js")
					navigator.serviceWorker.register(process.env.BASE_URL + "/sw.js")
						.then(registration => {
							console.log('Service Worker is registered', registration);
						})
						.catch(err => {
							console.error('Registration failed:', err);
						});
				});

                // Store the installation prompt for later use - in order to install the PWA as Desktop app from within the application itself.
                // NOTE: in case the application is already installed, the event beforeinstallprompt isn't triggered.
                // Once the event argument is stored (in this case in VueX), it can be used to prompt user for installation with e.prompt()
				window.addEventListener('beforeinstallprompt', (e) => {
                    // Prevent Chrome 67 and earlier from automatically showing the prompt
                    e.preventDefault();
                    // Stash the event so it can be triggered later.
                    that.goptions.serviceWorkerState.deferredInstallPrompt = e;
                    console.log("%c[SW] Stashed the deferred prompt", "color: magenta");
				});
			}
			else {
				console.log("Service worker support not available");
			}
		}

		// Add event listener for the transition from "uninstalled" PWA to "installed" application.
		// When the application is installed, this will hide the "install application" button from the UI.
		// This serves in case the user installs the application from the browser specific control (i.e.
		// small icon in the navnar, in Chrome, for example).
		// If "in app" installation is used (by clicking the "install" button in the UI), another code is
		// used - see installApp() method). that hides explicitely the button.
		window
			.matchMedia('(display-mode: standalone)')
			.addEventListener('change', ({ matches }) => {
				if (matches) {
					that.goptions.serviceWorkerState.deferredInstallPrompt = null
				}
			});

		// Read the local userName and password from local and store them in vuex.
		var localCredentials = this.getLocalCredentials();
		this.GLOBAL_StoreUserName(localCredentials.userName);
		this.GLOBAL_StoreUserPassword(localCredentials.password);
	},
	mounted: function() {
		// Initialize the background if the application is used for the first time.
		if (localStorage.getItem("backColor") == null) {
			localStorage.backColor = "#008888";
		}
		
		if (localStorage.getItem("tableBackground") == null) {
			localStorage.tableBackground = "bkgVelvet4.jpg";
		}

		if (localStorage.getItem("language") == null) {
			localStorage.language = "fr";
		}
		this.ML.translationOptions.defaultLanguage = localStorage.language;
	},

	methods: {
		...mapMutations([
				"GLOBAL_StoreUserPassword", "GLOBAL_StoreServerPassword", "GLOBAL_StoreUserName"
			])
	}



}

</script>