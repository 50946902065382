export const cardImagesInfo = {
	data() {
		return {
			cardImages: [
				{
					id: "none",
					img: "00-None.jpg",
					name: "None"
				},
				{
					id: "Bateleur",
					img: "01-Le Bateleur.jpg",
					name: "Le Bateleur"
				},
				{
					id: "Papesse",
					img: "02-La Papesse.jpg",
					name: "La Papesse"
				},
				{
					id: "Impératrice",
					img: "03-L'Impératrice.jpg",
					name: "L'Impératrice"
				},
				{
					id: "Empereur",
					img: "04-L'Empereur.jpg",
					name: "L'Empereur"
				},
				{
					id: "Pape",
					img: "05-Le Pape.jpg",
					name: "Le Pape"
				},
				{
					id: "Amoureux",
					img: "06-L'Amoureux.jpg",
					name: "L'Amoureux"
				},
				{
					id: "Chariot",
					img: "07-Le Chariot.jpg",
					name: "Le Chariot"
				},
				{
					id: "Justice",
					img: "08-La Justice.jpg",
					name: "La Justice"
				},
				{
					id: "Ermite",
					img: "09-L'Ermite.jpg",
					name: "L'Ermite"
				},
				{
					id: "Roue",
					img: "10-La Roue de Fortune.jpg",
					name: "La Roue de Fortune"
				},
				{
					id: "Force",
					img: "11-La Force.jpg",
					name: "La Force"
				},
				{
					id: "Pendu",
					img: "12-Le Pendu.jpg",
					name: "Le Pendu"
				},
				{
					id: "Mort",
					img: "13-(La Mort).jpg",
					name: "(Pas nommée)"
				},
				{
					id: "Tempérance",
					img: "14-Tempérance.jpg",
					name: "La Tempérance"
				},
				{
					id: "Diable",
					img: "15-Le Diable.jpg",
					name: "Le Diable"
				},
				{
					id: "Maison",
					img: "16-La Maison Dieu.jpg",
					name: "La Maison Dieu"
				},
				{
					id: "Étoile",
					img: "17-L'Étoile.jpg",
					name: "L'Étoile"
				},
				{
					id: "Lune",
					img: "18-La Lune.jpg",
					name: "La Lune"
				},
				{
					id: "Soleil",
					img: "19-Le Soleil.jpg",
					name: "Le Soleil"
				},
				{
					id: "Jugement",
					img: "20-Le Jugement.jpg",
					name: "Le Jugement"
				},
				{
					id: "Monde",
					img: "21-Le Monde.jpg",
					name: "Le Monde"
				},
				{
					id: "Fol",
					img: "22-Le Fol (Le Mat).jpg",
					name: "Le Fol (Le Mat)"
				}
			],
			cardPacks: [
				{
					id: "classicOld",
					name: "Clasic (vechi)",
					folder: "classicOld/"
				},
				{
					id: "classicNewBenDov",
					name: "Clasic (nou, Ben Dov)",
					folder: "classicNewBenDov/"
				},
				{
					id: "marrakesh",
					name: "Marrakesh",
					folder: "marrakesh/"
				},
				{
					id: "dali",
					name: "Dali",
					folder: "dali/"
				},
				{
					id: "classicNew",
					name: "Clasic (nou)",
					folder: "classicNew/"
				}
			],
			minorCardImages: [
				{
					id: "none",
					img: "00-None.jpg",
					name: "None"
				},
				{
					id: "baton_roy",
					img: "b14.jpg",
					name: "Roy de baton"
				},
				{
					id: "baton_reyne",
					img: "b13.jpg",
					name: "Reyne de baton"
				},
				{
					id: "baton_cavalier",
					img: "b12.jpg",
					name: "Cavalier de baton"
				},
				{
					id: "baton_valet",
					img: "b11.jpg",
					name: "Valet de baton"
				},
				{
					id: "baton_1",
					img: "b01.jpg",
					name: "1 de baton"
				},
				{
					id: "baton_2",
					img: "b02.jpg",
					name: "2 de baton"
				},
				{
					id: "baton_3",
					img: "b03.jpg",
					name: "3 de baton"
				},
				{
					id: "baton_4",
					img: "b04.jpg",
					name: "4 de baton"
				},
				{
					id: "baton_5",
					img: "b05.jpg",
					name: "5 de baton"
				},
				{
					id: "baton_6",
					img: "b06.jpg",
					name: "6 de baton"
				},
				{
					id: "baton_7",
					img: "b07.jpg",
					name: "7 de baton"
				},
				{
					id: "baton_8",
					img: "b08.jpg",
					name: "8 de baton"
				},
				{
					id: "baton_9",
					img: "b09.jpg",
					name: "9 de baton"
				},
				{
					id: "baton_10",
					img: "b10.jpg",
					name: "10 de baton"
				},

				{
					id: "coupe_roy",
					img: "c14.jpg",
					name: "Roy de coupe"
				},
				{
					id: "coupe_reyne",
					img: "c13.jpg",
					name: "Reyne de coupe"
				},
				{
					id: "coupe_cavalier",
					img: "c12.jpg",
					name: "Cavalier de coupe"
				},
				{
					id: "coupe_valet",
					img: "c11.jpg",
					name: "Valet de coupe"
				},
				{
					id: "coupe_1",
					img: "c01.jpg",
					name: "1 de coupe"
				},
				{
					id: "coupe_2",
					img: "c02.jpg",
					name: "2 de coupe"
				},
				{
					id: "coupe_3",
					img: "c03.jpg",
					name: "3 de coupe"
				},
				{
					id: "coupe_4",
					img: "c04.jpg",
					name: "4 de coupe"
				},
				{
					id: "coupe_5",
					img: "c05.jpg",
					name: "5 de coupe"
				},
				{
					id: "coupe_6",
					img: "c06.jpg",
					name: "6 de coupe"
				},
				{
					id: "coupe_7",
					img: "c07.jpg",
					name: "7 de coupe"
				},
				{
					id: "coupe_8",
					img: "c08.jpg",
					name: "8 de coupe"
				},
				{
					id: "coupe_9",
					img: "c09.jpg",
					name: "9 de coupe"
				},
				{
					id: "coupe_10",
					img: "c10.jpg",
					name: "10 de coupe"
				},

				{
					id: "epee_roy",
					img: "e14.jpg",
					name: "Roy d'épée"
				},
				{
					id: "epee_reyne",
					img: "e13.jpg",
					name: "Reyne d'épée"
				},
				{
					id: "epee_cavalier",
					img: "e12.jpg",
					name: "Cavalier d'épée"
				},
				{
					id: "epee_valet",
					img: "e11.jpg",
					name: "Valet d'épée"
				},
				{
					id: "epee_1",
					img: "e01.jpg",
					name: "1 d'épée"
				},
				{
					id: "epee_2",
					img: "e02.jpg",
					name: "2 d'épée"
				},
				{
					id: "epee_3",
					img: "e03.jpg",
					name: "3 d'épée"
				},
				{
					id: "epee_4",
					img: "e04.jpg",
					name: "4 d'épée"
				},
				{
					id: "epee_5",
					img: "e05.jpg",
					name: "5 d'épée"
				},
				{
					id: "epee_6",
					img: "e06.jpg",
					name: "6 d'épée"
				},
				{
					id: "epee_7",
					img: "e07.jpg",
					name: "7 d'épée"
				},
				{
					id: "epee_8",
					img: "e08.jpg",
					name: "8 d'épée"
				},
				{
					id: "epee_9",
					img: "e09.jpg",
					name: "9 d'épée"
				},
				{
					id: "epee_10",
					img: "e10.jpg",
					name: "10 d'épée"
				},

				{
					id: "deniers_roy",
					img: "d14.jpg",
					name: "Roy de deniers"
				},
				{
					id: "deniers_reyne",
					img: "d13.jpg",
					name: "Reyne de deniers"
				},
				{
					id: "deniers_cavalier",
					img: "d12.jpg",
					name: "Cavalier de deniers"
				},
				{
					id: "deniers_valet",
					img: "d11.jpg",
					name: "Valet de deniers"
				},
				{
					id: "deniers_1",
					img: "d01.jpg",
					name: "1 de deniers"
				},
				{
					id: "deniers_2",
					img: "d02.jpg",
					name: "2 de deniers"
				},
				{
					id: "deniers_3",
					img: "d03.jpg",
					name: "3 de deniers"
				},
				{
					id: "deniers_4",
					img: "d04.jpg",
					name: "4 de deniers"
				},
				{
					id: "deniers_5",
					img: "d05.jpg",
					name: "5 de deniers"
				},
				{
					id: "deniers_6",
					img: "d06.jpg",
					name: "6 de deniers"
				},
				{
					id: "deniers_7",
					img: "d07.jpg",
					name: "7 de deniers"
				},
				{
					id: "deniers_8",
					img: "d08.jpg",
					name: "8 de deniers"
				},
				{
					id: "deniers_9",
					img: "d09.jpg",
					name: "9 de deniers"
				},
				{
					id: "deniers_10",
					img: "d10.jpg",
					name: "10 de deniers"
				}
			]
		}
	}
}