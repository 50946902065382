const MultiLanguage = {
	state: {
		translations: {
			translationOptions: {
				defaultLanguage: "ro",
				defaultText: "???"
			},
			items: {
				referentialul_nasterii: {
					ro: "Referenţialul naşterii",
					en: "Birth Referential",
					fr: "Reférentiel de naissance"
				},
				persoana: {
					ro: "Persoană",
					en: "Person",
					fr: "Individu"
				},
				cuplu_si_alianta: {
					ro: "Cuplu si alianță",
					en: "Couple and alliance",
					fr: "Couple at alliance"
				},
				roi: {
					ro: "Roi",
					en: "Swarm",
					fr: "Ruche"
				},
				diferential: {
					ro: "Diferențial",
					en: "Differential",
					fr: "Diférrentiel"
				},
				potential: {
					ro: "Potențial",
					en: "Potential",
					fr: "Potentiel"
				},
				meditation: {
					ro: "Meditație",
					en: "Meditation",
					fr: "Méditation"
				},
				compute: {
					ro: "Calculează",
					en: "Compute",
					fr: "Calculer"
				},
				limba: {
					ro: "Limba",
					en: "Language",
					fr: "Langue"
				},
				optiuni: {
					ro: "Optiuni",
					en: "Options",
					fr: "Options"
				},
				set_tarot: {
					ro: "Set Tarot",
					en: "Tarot Set",
					fr: "Jeu Tarot"
				},
				inchide_la_recalculare: {
					ro: "Închide panel date la calculare",
					en: "Close dates panel on re-calculate",
					fr: "Fermer le paneau de dates au recalcul"
				},
				logout: {
					ro: "Deconectare",
					en: "Logout",
					fr: "Se déconnecter"
				},
				install: {
					ro: "Instalează",
					en: "Install",
					fr: "Installer"
				},
				fundal: {
					ro: "Fundal",
					en: "background",
					fr: "Fond écran"
				},
				aspect: {
					ro: "Aspect",
					en: "Aspect",
					fr: "Aspect"
				},
				aspecte: {
					ro: "Aspecte",
					en: "Aspects",
					fr: "Aspects"
				},
				inchide: {
					ro: "Închide",
					en: "Close",
					fr: "Fermer"
				},
				perechi: {
					ro: "Perechi",
					en: "Pairs",
					fr: "Paires"
				},
				banda: {
					ro: "Banda",
					en: "Stripe",
					fr: "Bande"
				},
				dialectica: {
					ro: "Dialectica",
					en: "Dialectic",
					fr: "Dialectique"
				},
				oglinzile: {
					ro: "Oglinzile",
					en: "Mirrors",
					fr: "Miroirs"
				},
				date_incorecte: {
					ro: "Exista date incorecte",
					en: "Incorrect dates",
					fr: "Dates incorrectes"
				},


				afectiv: {
					ro: "Afectiv",
					en: "Affective",
					fr: "Affectif"
				},
				uman: {
					ro: "Uman",
					en: "Human",
					fr: "Humain"
				},
				de_vindecare: {
					ro: "De vindecare",
					en: "Healing",
					fr: "De guérison"
				},
				creativ: {
					ro: "Creativ",
					en: "Creative",
					fr: "Créatif"
				},
				perceptiv: {
					ro: "Perceptiv",
					en: "Perceptive",
					fr: "Perceptif"
				},
				de_intelepciune: {
					ro: "De intelepciune",
					en: "Wisdom",
					fr: "De sagesse"
				},
				de_supravietuire: {
					ro: "De supravietuire",
					en: "Survival",
					fr: "Survie"
				},

				min_suite_sword: {ro: "Spada", en: "Sword", fr: "Epée"},
				min_suite_baton: {ro: "Bata", en: "Baton", fr: "Bâton"},
				min_suite_coin: {ro: "Moneda", en: "Coin", fr: "Coin"},
				min_suite_cup: {ro: "Cupa", en: "Cup", fr: "Coupe"},
			},
			trans: function(textId, languageId, defaultText) {
				languageId = (languageId == null ? this.translationOptions.defaultLanguage : languageId);
				defaultText = (defaultText == null ? this.translationOptions.defaultText : defaultText);
				
				var translation = null;
				try {
					var translation = this.items[textId][languageId];
				}
				catch(ex) {
					console.error("======== err: " + ex);
				}
				return (translation != null ? translation : defaultText);
			},
			getAllTranslationIds: function() {
				return Object.keys(this.items);
			}
		}
	}
}

export default MultiLanguage
