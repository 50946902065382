export const housesDocumentation = {
	data() {
		return {
			housesDocument:
				[
					{
						id: 0,
						title: "none",
						doc: "[doc] none"
					},
					{
						id: 1,
						title: "Casa 1 : Casa personalității",
						doc: "<p>Această casă reprezintă &icirc;n principal toate forțele de extraversiune de care o persoană dispune : at&acirc;t personalitatea sa c&acirc;t și modul de comunicare cu mediul exterior. Cartea care ocupă această poziție este mai mult o carte de vizită dec&acirc;t cartea sa de identitate.</p><p>Cum ne aflăm &icirc;n cadrul unui proces de evoluție personală vom interpreta Casa 1 din două puncte de vedere : pe de o parte imaginea de sine pe care o lăsăm la vedere, cu care ne prezentăm &icirc;n fața celorlați, iar pe de altă parte ceea ce putem să aducem noi personal celor din jurul nostru.</p><p>Georges Clooney are &icirc;n Casa 1 arcana VI, &Icirc;ndrăgostitul, arcană ce ilustrează destul de bine șarmul, magnetismul și aura seducătoare de care dispune cunoscutul actor. &Icirc;nsă, la un nivel mult mai &icirc;nalt, această arcană ne amintește și de angajamentul &icirc;ntr-un domeniu umanist, fapt care &icirc;l caracterizează destul de bine pe acesta.&nbsp;</p><p>Barack Obama, născut pe 4 august 1961 oferă cu ''Imparatul'' ce-i ocupă prima casă a Referențialului imaginea unui om stabil, solid, creativ și rațional. Acesta ne duce cu g&acirc;ndul la imaginea unui imperiu, unui teritoriu și denotă forță și o anumită formă de autoritate.</p>"
					},
					{
						id: 2,
						title: "Casa 2 : Casa căutărilor interioare",
						doc: "<p>Iată casa idealurilor, locul &icirc;n care se află &icirc;nscrisă lama care ilustrează dorința noastră de absolut, partea noastră lipsă, căutarea noastră interioară. Casa 2 relevă partea interioară a subiectului spre deosebire de Casa 1 care lasă să se vadă partea exteriorioară a acestuia.</p>"
					},
					{
						id: 3,
						title: "Casa 3 : Casa gândirii, sediul dorințelor și temerilor",
						doc: "<p>Ne aflăm aici pe domeniul verbului ''a g&acirc;ndi''. Aceasta este casa limitărilor . Casa 3 evocă preocupările constante ale g&acirc;ndirii, frăm&acirc;ntările mentalului, umbra pe care intelectul - exigența analizei - o aruncă asupra conștiintei și a ființei umane. Cartea ce se găsește aici indică unghiul din care percepem realitatea</p>"
					},
					{
						id: 4,
						title: "Casa 4 : Casa acțiunii, sediul misiunii existențiale",
						doc: "<p>Casa 4 reprezintă casa verbului '' a face''. Arcana majoră ce se află &icirc;n această poziție ne indică propunerile ce ni se dau &icirc;n ceea ce privește alegerile noastre existențiale. Ne sunt insuflate diferite orientări și chiar modul &icirc;n care ne putem angaja &icirc;n domeniul cel mai concret posibil.&nbsp;</p><p>Casa 4 indică de asemenea posibilitățile de realizare personală .</p><p>Reflecțiile asupra arcanei aflate &icirc;n această casă reprezintă un excelent mijloc de a &icirc;și lua &icirc;n posesie propria viață prin reperarea obiectivelor precise, prin angajarea &icirc;ntr-un proces creativ.&nbsp;</p><p>Charles de Gaulle cu Diavolul &icirc;n Casa 4 alege puterea, Sigmund Freud cu Nebunul &icirc;n Casa 4 alege să-și construiască imperiul la frontiera dintre &icirc;nțelepciune și nebunie, Obama cu Judecata &icirc;n Casa 4 &icirc;și ia angajamentul ferm de a trezi conștiințelor celor din jur.</p><p>Bine&icirc;nțeles, mesajul trimis de către Casa 4 nu este &icirc;ntotdeauna ușor de &icirc;nțeles. Astfel, prezența aceleiași arcane &icirc;n Casa misiunii existențiale la două persoane diferite nu le va predispune la realizarea unei existențe identice și nici la &icirc;ndeplinirea aceluiași destin. Din această cauză prefer mai degrabă să vorbesc de ''mandat'' sau ''&icirc;mputernicire'' dec&acirc;t de destin sau misiune.</p>"
					},
					{
						id: 5,
						title: "Casa 5",
						doc: "<p>Casa 5 reprezintă chintesența primelor patru arcane. O numim carte de sinteză sau trecerea obligatorie. Casa 5 dezvăluie ceea ce trebuie să confruntăm mereu de-a lungul existenței noastre, un mister ce va trebui descifrat și clarificat. Este trecerea obligatorie pentru ca tema personală să poată funcționa. Pentru a putea trăi Casa 4 și a ne &icirc;ndeplini misiunea existențială trebuie neapărat să trecem mai inai prin Casa 5. Cele două case se află &icirc;ntr-o legătură indisociabila : una este determinată de cealaltă.</p>"
					},
					{
						id: 6,
						title: "Casa 6 : Casa resurselor",
						doc: "<p><i>Casele 6 și 7 din Referențial se studiază &icirc;mpreună. Ele reprezintă un cuplu de opoziții.</i></p><p>Casa 6, Casa resurselor, este cea a calităților &icirc;nnăscute, a talentelor, a tot ceea ce putem realiza de-a lungul existenței fără a fi mereu conștienți de asta. Ea conferă o ultimă soluție problemelor aparent fără de rezolvare. Prezența Ermitului &icirc;n Casa 6, de exemplu, reprezintă o salvare pentru oricine &icirc;nt&acirc;mpină dificultăți &icirc;n viața socială. Ermitul ne propune să intrăm &icirc;n ad&acirc;ncul ființei noastre ca și &icirc;ntr-un refugiu providențial. Pentru aceasta putem apela la meditație sau de ce nu, la contemplație sau starea plăcută a visului.</p>"
					},
					{
						id: 7,
						title: "Casa 7 : Casa provocărilor",
						doc: "<p><i>Casele 6 și 7 din Referențial se studiază &icirc;mpreună. Ele reprezintă un cuplu de opoziții.</i></p><p>Casa 7, Casa provocărilor, conține arcana ce reprezintă nevoile noastre fundamentale, barajele energetice, dificultățile psihologice, obstacolele ce trebuie depășite ... pe scurt, lecțiile pe care le avem de &icirc;nvățat &icirc;n viață.&nbsp;</p><p>0Spre exemplu, prezența Arcanei XII, Sp&acirc;nzuratul &icirc;n Casa 7 va sugera o mare dificultate de a renunța, de a se lasă liber, o lipsă totală de &icirc;ncredere &icirc;n sine sau &icirc;n existență, o rezistență &icirc;n a imagina lucrurile dintr-un unghi diferit dec&acirc;t cel adoptat &icirc;n mod obișnuit, dar și o reală blocare asupra propriului punct de vedere și o imposibilitate aproape patologică &icirc;n a realiza o inversare a valorilor.&nbsp;</p><p>Bine&icirc;nțeles, lucrurile nu sunt bătute &icirc;n cuie. Referențialul fiind &icirc;ntr-o continuă evoluție, ne așteptăm ca atunci c&acirc;nd o persoană devine conștientă de dificultățile și provocările pe care i le rezervă Casa 7 să &icirc;nceapă a le combate și a le transforma &icirc;n calități. Numeroși scriitori și artiști au &icirc;n Casa 7 &Icirc;mpărăteasa, arcana a cărei funcție de expresie și creație ne-o amintim foarte bine. Dacă numeroși creatori sunt at&acirc;t de &icirc;nzestrați este probabil tocmai pentru că au reușit să transfome un obstacol &icirc;ntr-o trambulină, refuz&acirc;nd să se supună oricarei forme de destin. Madona, Louis Armstrong, Albert Camus sau Michael Jackson au cu toții &Icirc;mpărăteasa &icirc;n Casa 7. Această dificultate de a se exprima și de a crea este aici depășită, supra-compensată și transformată la superlativ.</p>"
					},
					{
						id: 8,
						title: "Casa 8 : Casa transformărilor : Meteo",
						doc: "<p>Arcana prezentă aici indică energiile disponibile de-a lungul unui an &icirc;ntreg, un adevărat ajutor pentru realizarea Referențialului. Nu este vorba despre anticiparea unui destin ci de definirea unei propuneri punctuale, o invitație de a introduce această arcana &icirc;n tema personală pe o perioadă determinată.</p><p>Casa 8 se schimbă &icirc;ntre octombrie și decembrie și dă o anumită culoare anului ce urmează.</p>"
					},
					{
						id: 9,
						title: "Casa 9 : Casa Sinelui, Casa reușitei",
						doc: "<p>Casa 9 reprezintă Casa Sinelui. Arcana pe care o conține această casă din Referențial reprezintă o parte esențială a subiectului, o parte ce nu se exprimă dec&acirc;t extrem de rar, &icirc;n vise sau &icirc;n creații ce implică inconștientul. Această arcană va vibra la cel mai &icirc;nalt nivel la cei care au reușit să &icirc;și &icirc;mplinească destinul sau să pregătească un teren fertil gata să găzduiască cele mai bune recolte.&nbsp;</p><p>Arcana prezentă &icirc;n această casă nu poate niciodată să fie interpretată &icirc;n mod negativ, fiecare carte de Tarot conțin&acirc;nd c&acirc;te o calitate, calitate invitată să se dezvolte cu deosebire &icirc;n Casa 9.&nbsp;</p><p>Spre exemplu Nelson Mandela reușește să-și activeze Soarele &icirc;ntr-un demers ce implică ''umanism și fraternitate'', Ghandi cu Casa Domnului &icirc;n această poziție reușește ''transmutarea violenței'', Martin Luther King cu Ermitul &icirc;n Casa 9 transmite &ldquo;&icirc;nțelepciune, iluminare și călăuzire&rdquo;.</p>"
					},
					{
						id: 10,
						title: "Casa 10 : Casa eșecurilor și experiențelor",
						doc: "<p>Aceasta este Casa umbrei.</p><p>Arcana conținută aici poate indică domeniul &icirc;n care subiectul cunoaște cel mai des eșecul (&Icirc;mpăratul, eșec material, Luna, eșec emoțional, &Icirc;mpărătească, eșec pe partea de exprimare ...). Această noțiune de eșec nu trebuie &icirc;nsă abordată cu fatalism. &Icirc;nțelegerea profundă și exactă a simbolisticii arcanei prezente aici ne poate da informații prețioase &icirc;n ceea ce privește programările inconștiente și schemele repetitive pe care le urmăm. Odată ce Casa 10 și-a livrat secretul, aceasta devine un loc privilegiat al experiențelor și constituie un punct de sprijin solid pentru subiect.</p><p>Cum este adesea invocat &icirc;n NLP (programare neuro-lingvistică), eșecul există doar atunci c&acirc;nd credem &icirc;n el. Credințele noastre ne limitează. Nu există eșec ci doar rezultate neașteptate. Un drum fără eșec este un drum fără așteptări.</p><p>De aceea, Casa 10 exprimă eșec at&acirc;ta timp c&acirc;t aceasta nu a fost validată ca și experiență. Pe de altă parte această Casa 10 reprezinta cutia neagră a temei, &icirc;n simbolistica ei afl&acirc;ndu-se &icirc;nregistrate experiențele trăite &icirc;ncă de la naștere și p&acirc;nă la momentul prezent.</p>"
					},
					{
						id: 11,
						title: "Casa 11 : captor - înregistrator al amintirilor subiectului",
						doc: "<p><em>Casele 11 si 12 vor fi studiate &icirc;mpreună, ele conțin&acirc;nd amintirile subiectului. Amintirile din trecut pentru Casa 11 și &laquo;amintirile din viitor&raquo; pentru Casa 12.</em></p><p>Arcana ce ocupă această casă simbolizează: calitățile, talentele &icirc;nnăscute, moștenirea karmică , genetică sau genealogică cu care individul a venit pe lume și pe care se poate sprijini &icirc;n procesul său de evoluție. &Icirc;n mod paradoxal &icirc;nsă acestea pot constitui o fr&acirc;nă. Avem nevoie de bagaje atunci c&acirc;nd călătorim &icirc;nsă acestea pot constitui o piedică atunci c&acirc;nd dorim să ne deplasăm cu lejeritate.</p><p>Casa 11 se mai numește și <em>proiectul parental inconștient</em> și se află &icirc;n legătură directă cu Casa 4 și cu Casa 3.</p><p>Casa 4, dacă ne amintim, reprezenta proiectul personal. Procesul de evoluție constă &icirc;n trecerea de la proiectul parental la proiectul personal. Pentru că aceasta va fi nevoie să trecem prin Casa 3, casă ce ne poate oferi cheia necesară acestei treceri.</p>"
					},
					{
						id: 12,
						title: "Casa 12 : Valoarea Personală Transmisă",
						doc: "<p><em>Casele 11 si 12 vor fi studiate &icirc;mpreună, ele conțin&acirc;nd amintirile subiectului. Amintirile din trecut pentru Casa 11 și &laquo;amintirile din viitor&raquo; pentru Casa 12.</em></p><p>Un adevărat far al Referențialului, această casă indică destinațiile cele mai elevate spre care subiectul poate tinde. Arcana idealului, a motivației sublimate, o considerabilă forță magnetică, arcana ce ocupă această casă conferă subiectului energia necesară pentru a se putea proteja pe viitor. Ea reprezintă totodată și patrimoniul spiritual și valorile pe care subiectul le va transmite mai departe descendenților săi.</p><p> Arcana prezentă &icirc;n Casa 12 indică imaginea personală pe care subiectul o va lăsa mai departe posteriorității. Puterea acestei arcane o transformă &icirc;ntr-o arcană vindecătoare a Referențialului, orice vindecare a ființei umane venind din ceea ce o transcede.</p>"
					},
					{
						id: 13,
						title: "Casa 13 : Casa paradoxului,	 numită și Inima Blazonului",
						doc: "<p>Casa 13, Inima Blazonului reprezintă sediul conflictelor personale, conflicte care, at&acirc;t timp c&acirc;t nu sunt nici rezolvate nici conștientizate se proiectează asupra celor din jur.</p><p>Inima Blazonului evidențiază paradoxul fundamental cu care se poate confrunta o persoană de-a lungul vieții. Vom lua &icirc;n considerare &icirc;n această situație cele două aspecte contradictorii ale arcanei această casă oferind indicații prețioase at&acirc;t asupra conflictelor interioare c&acirc;t și asupra modului &icirc;n care pot fi depășite. O arcană reprezintă &icirc;n același timp at&acirc;t broască c&acirc;t și cheia. Acest paradox trebuie depășit fără a trebui să proiectăm conflictele personale asupra relațiilor cu ceilalți.</p><p>Arcana prezentă &icirc;n Casa 13 ne atrage atenția asupra a ceea ce limitează dezvoltarea noastră personală, ceea ce ne trage &icirc;n jos &icirc;nsă ne &icirc;nvață totodată cum să depășim obstacolele, cum să ne ridicăm atunci c&acirc;nd am căzut. Putem concluziona că arcana prezentă &icirc;n Casa 13 se interpretează &icirc;n același mod ca atunci c&acirc;nd Casa 6 este egală cu Casa 7.</p><p>Inima Blazonului constituie problematica fundamentală a individului așadar, &icirc;ntr-o formă ambivalentă, at&acirc;t sursa dificultăților c&acirc;t și capacitatea de a le rezolva. Cu abordarea acestei case ne aflăm &icirc;n inima problemei, ne apropiem de natura profundă, esențială și paradoxală a persoanei analizate. Fiecare problematică, &icirc;n funcție de arcana ce se află &icirc;n casa 13, poartă un nume. Acestea vor fi tratate &icirc;n capitolul despre aspectele Referențialului.</p>"
					},
					{
						id: 14,
						title: "Casa 14",
						doc: "Casa 14..."
					}
				]
		}
	}
}
