export const housesInfo = {
	data() {
		return {
			houses:
				[{
					id: 12,
					title: "c12",
					left: 400,
					top: 100,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 1,
					title: "c1",
					left: 400,
					top: 300,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 5,
					title: "c5",
					left: 400,
					top: 500,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 3,
					title: "c3",
					left: 400,
					top: 700,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 11,
					title: "c11",
					left: 400,
					top: 900,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				},


				{
					id: 9,
					title: "c9",
					left: 100,
					top: 500,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 2,
					title: "c2",
					left: 250,
					top: 500,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 4,
					title: "c4",
					left: 550,
					top: 500,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				},


				{
					id: 6,
					title: "c6",
					left: 230,
					top: 280,
					width: 100,
					rotate: -35,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 7,
					title: "c7",
					left: 230,
					top: 720,
					width: 100,
					rotate: 35,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 8,
					title: "c8",
					left: 570,
					top: 280,
					width: 100,
					rotate: 35,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 10,
					title: "c10",
					left: 570,
					top: 720,
					width: 100,
					rotate: -35,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 13,
					title: "c13",
					left: 800,
					top: 300,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				},
				{
					id: 14,
					title: "c14",
					left: 800,
					top: 700,
					width: 100,
					rotate: 0,
					cardIndex: 0,
					highlighted: false
				}
			],
			generateRap: function(year, month, day) {
				// Returns a new rap object.
				var newRap = {
					year: year,
					month: month,
					day: day,
					houses: [],
					label: ""
				};
				for (var i = 0; i < this.houses.length; i++) {
					newRap.houses.push(  {...this.houses[i]}  );
				}

				return newRap;
			},
			generateRapSet: function(arrRaps, calculationMode) {
				// arrRaps - array of rap objects
				// Generates and returns a new rap set.
				var newRapSet = {
					calculationMode: calculationMode,
					rapSources: arrRaps,
					rapResult: this.generateRap(0, 0, 0)
				};
				return newRapSet;
			},
			getSignificantDates: function(datesArr, calculationMode) {
				// Returns a new array by considering a number of elements from "datesArr",
				// Depending on "calculationMode".
				var retDatesCount = 0;
				switch(calculationMode) {
					case "single":
						retDatesCount = 1;
						break;
					case "diff":
						retDatesCount = 1;
						break;
					case "couple":
						retDatesCount = 2;
						break;
					case "family":
						retDatesCount = datesArr.length;
						break;
					case "potential":
						retDatesCount = 1;
						break;
					}
				return datesArr.slice(0, retDatesCount);
			}
		}
	}
}