<template>
	<div ref="modalContainer" class="overlay center-h center-v" style="overflow-y: auto;" v-bind:style="{'visibility': cssVisibility, 'opacity': opacity, 'transition': transDuration, 'background-color': backcolorOverlay}">
		<!-- <div ref="topObject" style="background-color: grey; color: red;"> -->
			<slot style=""></slot>
		<!-- </div> -->
	</div>
</template>

<script>
export default {
	name: "ModalPopupFade",
	data: function() {
		return {
			opacity: 0,
			cssVisibility: "hidden",
			unregisterRouterGuard: null
		};
	},
	props: {
		isInteractive: {
			// If True (the default) the modal popup is closed when the "back" button is clicked (
			// in fact it emits the askClose event). If False, no navigation guard is added.
			// The False value must be used when the modal popup is NOT meant to be interactive,
			// for example when it displays a simple "wait" message.
			// Reason: if the navigation guard would be used by the "wait" popup, and if the popup is
			// closed + a navigation is immediately required (this.router.push(...)), the navigation guard
			// in the ModalPopupFade is removed asynchronous, probably after the navigation to the new
			// page is initiated => the navigation is canceled by Vue, with an error.
			type: Boolean,
			default: true
		},
		visible: {
			type: Boolean,
			default: false
		},
		transDuration: {
			type: String,
			default: "0.5s"
		},
		backcolorOverlay: {		// Color of the overlay (fullscreen background of the popup)
			type: String,
			default: "#000000bb"
		},
		popupLayoutClass: {		// Popup layout class name
			type: String,
			default: ""
		},
	},
	watch: {
		visible: function() {
			if (this.visible) {
				window.addEventListener("keydown", this.onKeydown);

				// Scroll to top.
				// Reason: if the content is big (higher than the window height), and if the
				// user scrolls down the content, when the popup is displayed next time the
				// content would appear initially scrolled - not nice.
//				this.$refs.topObject.scrollIntoView();
				this.cssVisibility = "visible";
				this.$refs.modalContainer.style.zIndex="9999";
				this.opacity = 1;


				if (this.isInteractive) {
					// Back closes the modal popup instead of navigating back.
					this.unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
						if (this.visible) {
							this.doClose();
							next(false);
						}
					});
				}
			}
			else {
				window.removeEventListener("keydown", this.onKeydown);
				
				this.opacity = 0;
				this.cssVisibility = "hidden";

				if (this.isInteractive) {
					// Unregister the route guard to come back to the default browser's "back" button behavior.
					this.unregisterRouterGuard();
				}
			}
		}
	},
	computed: {
	},
	created: function() {
	},
	unmounted: function() {
	},
 	methods: {
		onKeydown: function(event) {
			// Manage the Esc key down
			if (event.keyCode == 27) {
				// Close the modal popup
				this.doClose();
			}
		},
		doClose: function() {
			this.$emit("askClose", {});
		}
	}
}
</script>

<style scoped>
  /*=============== Modal - fullscreen ==================*/
/* The Overlay (background) */
  .overlay {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index:9999;
	opacity: 0;
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	background-color: rgb(255,0,0); /* Black fallback color */
	/*background-color: rgba(0,0,0, 0.9);*/ /* Black w/opacity */
	overflow-x: hidden; /* Disable horizontal scroll */
	transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }
  
  /* Position the content inside the overlay */
  .overlay-content {
	position: relative;
	top: 0px; /* 25% from the top */
	width: 100%; /* 100% width */
	/*text-align: center;*/ /* Centered text/links */
	margin-top: 0px; /* 30px top margin to avoid conflict with the close button on smaller screens */
	/* color: red; */
	/*font-size: 5mm;*/
  }
  
  /* When you mouse over the navigation links, change their color */
 /*
  .overlay a:hover, .overlay a:focus {
	color: #f1f1f1;
  }

  /* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
 /* 
  @media screen and (max-height: 450px) {
	.overlay a {font-size: 20px}
	.overlay .closebtn {
	  font-size: 40px;
	  top: 15px;
	  right: 35px;
	}
  }

  @media screen and (max-width: 400px) {
	.overlay-content {
		font-size: 10mm;
	}
}
*/
.overlay .pop-title {
	height: 60px;
	font-size: 20px;
	font-weight: bold;
	padding: 10px;
	background-color: #dddddd;
	border: 1px solid #555555;
	border-radius: 10px;
}

.center-v {
	display: flex;
	align-items: center;
}

.center-h {
	display: flex;
	justify-content: center;
}

/* Close button */
/* https://codepen.io/brissmyr/pen/egidw */
.overlay .close {
	position: absolute;
	right: 15px;
	top: 15px;
	width: 0px;
	height: 0px;
	opacity: 0.5;
  }

.overlay .close:hover {
	opacity: 1;
  }

.overlay .close:before, .close:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 0px;
	width: 2px;
	background-color: #000;
  }
 
.overlay .close:before {
	transform: rotate(45deg);
  }

.overlay .close:after {
	transform: rotate(-45deg);
  }
</style>